import { SYSLOG_SEVERITIES } from "@/lib/severities";

import { applyEffects } from "./effects";
import { createDefaultMeta } from "./meta";

export function createTrace({ effects, meta: maybeDefaultMeta }) {
  const defaultMeta = createDefaultMeta(maybeDefaultMeta);

  return (thisCallOptions) => {
    const { label, meta: thisCallMeta, severity } = decomposeThisCallOptions(thisCallOptions);
    const meta = {
      ...defaultMeta(),
      ...thisCallMeta,
    };
    const start = Date.now();

    return async () => {
      const ellapsed = Date.now() - start;
      const message = `trace[${label}]: ${ellapsed}ms`;

      await applyEffects({ effects, message, meta, severity });
    };
  };
}

function decomposeThisCallOptions(userOptions) {
  const defaultTraceSeverity = SYSLOG_SEVERITIES.INFO;

  if (typeof userOptions === "string")
    return { label: userOptions, meta: {}, severity: defaultTraceSeverity };

  // todos os campos extra usados na chamada são tratados como metadados. essa opção ao contrário de exigir um objeto meta especifico facilita que objetos personalizados possam ser passados diretamente ao logger e tudo que não é severity e message é automaticamente envelopado em meta.
  const { label, meta: innerMeta = {}, severity = defaultTraceSeverity, ...metaInOptions } = userOptions;
  return { label, meta: { ...innerMeta, ...metaInOptions }, severity };
}
