<template>
  <ControlWrapper
    :control-component="controlComponent"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { InputRichText } from "@/lib/rich-text-suite";

import { ControlWrapper } from "../control-wrapper";

export default {
  components: { ControlWrapper },
  data() {
    return {
      controlComponent: InputRichText,
    };
  },
  name: "ControlRichText",
};
</script>
