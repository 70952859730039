import { encodeClient } from "./encode";

export function createClientsEndpoints({ backendApi, pageLimit }) {
  const clientsUrl = "/v1/clientes";
  return {
    clientAdd: backendApi.createEndpoint({
      // a transformação do client só é necessárias no post pq o backend exige um formato específico para inclusão de cliente que não se aplica na edição
      encode: encodeClient,
      method: "post",
      url: clientsUrl,
    }),

    clientEdit: backendApi.createEndpoint({
      method: "put",
      url: client => `${clientsUrl}/${client.id}`,
    }),

    clientsList: backendApi.createEndpoint({
      method: "get",
      url: page => `${clientsUrl}?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),
  };
}
