export function openIndexedDB({ dbNameOrGetter, stores, version }) {
  return new Promise((resolve, reject) => {
    if (!dbNameOrGetter) {
      reject("indexed database name is required to perform an open operation");
      return;
    }

    // algumas apis podem receber uma função que retorna o nome do banco de dados quando o banco é baseado no id do usuário, por exemplo.
    const dbName = typeof dbNameOrGetter === "function"
      ? dbNameOrGetter()
      : dbNameOrGetter;

    const request = indexedDB.open(dbName, version);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      stores
        .filter(name => !db.objectStoreNames.contains(name))
        .forEach(name => db.createObjectStore(name, { keyPath: "id" }));
    };
  });
}
