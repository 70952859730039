<template>
  <FragmentBase>
    <ButtonSecondary
      :field="camelCase('interessado', 'link')"
      label="+ Vincular Interessado"
      size="sm"
      @click="handleAttach"
    />
    <FlowFormStakeholdersTabManagerAttachModal
      ref="modal"
      :codigo-api="codigoApi"
      :contas="contas"
    />
  </FragmentBase>
</template>

<script>
import { ButtonSecondary, FragmentBase, camelCase } from "@/lib";

import FlowFormStakeholdersTabManagerAttachModal from "./flow-form-stakeholders-tab-manager-attach-modal";

export default {
  components: {
    ButtonSecondary,
    FlowFormStakeholdersTabManagerAttachModal,
    FragmentBase,
  },
  methods: {
    camelCase,
    async handleAttach() {
      const interessado = await this.$refs.modal.show();
      if (!interessado) return;

      const isAlreadyLinked = this.interessados.find(
        existingInteressado =>
          existingInteressado.codigo === interessado.codigo,
      );
      if (isAlreadyLinked) {
        return this.$notifyError(
          `Não foi possível incluir o interessado ${interessado.nome}. Um interessado com o código ${interessado.codigo} já está vinculado ao fluxo`,
        );
      }

      interessado.ordem = this.isSequencial ? this.interessados.length + 1 : 0;
      const newInteressados = [...this.interessados, interessado];
      this.$emit("update:interessados", newInteressados);
    },
  },
  name: "FlowFormStakeholdersTabManagerAttachButton",
  props: {
    codigoApi: {
      required: true,
      type: Function,
    },
    contas: {
      default: null,
      type: Array,
    },
    interessados: {
      default: () => [],
      type: Array,
    },
    isSequencial: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
