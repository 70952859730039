var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "skeleton-loader" },
    _vm._l(_setup.lineWidths, function (width, index) {
      return _c("div", {
        key: index,
        staticClass: "skeleton-line",
        style: { width: width + "%" },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }