import { provideRouter } from "@/lib";
import Vue from "vue";
import VueRouter from "vue-router";

import { createGuardRoute } from "./guard-route";
import { createRoutes } from "./routes";
import { createDefaultRoute } from "./routes-default";

export function initRouter({ environment, store }) {
  Vue.use(VueRouter);

  const serproIdBaseUrl = environment.get("WEB_SERPRO_ID_URL");
  const serproIdClientId = environment.get("WEB_SERPRO_ID_CLIENT_ID");
  const serproIdRedirectPath = environment.get("WEB_SERPRO_ID_REDIRECT");
  const routes = createRoutes({ serproIdBaseUrl, serproIdClientId, serproIdRedirectPath });

  const router = new VueRouter({
    mode: "history",
    routes,
  });

  const isOnMaintenance = environment.is("WEB_ON_MAINTENANCE");
  const profileGetter = () => store.getters["auth/perfil"];
  const isSignedInGetter = () => store.getters["auth/isSignedIn"];

  router.addRoute(createDefaultRoute(profileGetter));
  router.beforeEach(createGuardRoute({ isOnMaintenance, isSignedInGetter, profileGetter }));

  provideRouter(router);

  return router;
}
