var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "TabAccordion",
    _vm._b(
      {
        attrs: {
          field: "documentos",
          "header-title": "Documentos",
          "header-icon-name": "files",
          "header-icon-badge": _vm.howManyDocumentos,
        },
      },
      "TabAccordion",
      _vm.$attrs,
      false
    ),
    [
      _c("FlowFormDocumentsTabManager", {
        attrs: { field: "fluxo", fluxo: _vm.fluxo, disabled: _vm.disabled },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }