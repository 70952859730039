var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "notifications-global" }, [
    _c(
      "div",
      { staticClass: "notifications-global__toasts" },
      _vm._l(_setup.notificationsList, function (notification) {
        return _c(
          "div",
          { key: notification.id },
          [
            _c(_setup.NotificationsToast, {
              attrs: { notification: notification },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }