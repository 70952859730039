<template>
  <div>
    <PanelButtons>
      <template #start>
        <InfoText>
          Pessoas que deverão responder ao(s) documento(s) do fluxo.
        </InfoText>
      </template>
      <template #default>
        <FlowFormStakeholdersTabManagerAttachButton
          v-if="!disabled"
          :is-sequencial="isSequencial"
          :interessados="interessados"
          :codigo-api="codigoApi"
          :contas="contas"
          @update:interessados="$emit('update:interessados', $event)"
        />
      </template>
    </PanelButtons>
    <ListPower
      field="interessados"
      :items="interessados"
      :fields="fields"
      :initial-sort="initialSort"
      v-bind="$attrs"
    >
      <template #actions="{ item, index }">
        <FlowFormStakeholdersTabManagerButtonLink
          :field="index"
          :id-fluxo="idFluxo"
          :is-fluxo-iniciado="isFluxoIniciado"
          :interessado="item"
        />
        <FlowFormStakeholdersTabManagerButtonsOrder
          :field="index"
          :interessados="interessados"
          :interessado="item"
          :hide="disabled || !isSequencial"
          @update:interessados="$emit('update:interessados', $event)"
        />
        <FlowFormStakeholdersTabManagerButtonDetach
          :field="index"
          :is-sequencial="isSequencial"
          :interessados="interessados"
          :interessado="item"
          :hide="disabled"
          @update:interessados="$emit('update:interessados', $event)"
        />
      </template>
    </ListPower>
  </div>
</template>

<script>
import { InfoText, ListPower, PanelButtons } from "@/lib";

import FlowFormStakeholdersTabManagerAttachButton from "./flow-form-stakeholders-tab-manager-attach-button";
import FlowFormStakeholdersTabManagerButtonDetach from "./flow-form-stakeholders-tab-manager-button-detach";
import FlowFormStakeholdersTabManagerButtonLink from "./flow-form-stakeholders-tab-manager-button-link";
import FlowFormStakeholdersTabManagerButtonsOrder from "./flow-form-stakeholders-tab-manager-buttons-order";

export default {
  components: {
    FlowFormStakeholdersTabManagerAttachButton,
    FlowFormStakeholdersTabManagerButtonDetach,
    FlowFormStakeholdersTabManagerButtonLink,
    FlowFormStakeholdersTabManagerButtonsOrder,
    InfoText,
    ListPower,
    PanelButtons,
  },
  computed: {
    fields() {
      const ordemColumn = this.isSequencial
        ? [{ key: "ordem", label: "Ordem", sortable: true }]
        : [];
      return [
        ...ordemColumn,
        { key: "nome", label: "Nome", sortable: true },
        { key: "codigo", label: "Código", sortable: true },
        { key: "email", label: "E-Mail", sortable: true },
        { key: "papel", label: "Papel", sortable: true },
      ];
    },
    initialSort() {
      return {
        direction: "asc",
        field: this.isSequencial ? "ordem" : "nome",
      };
    },
  },
  name: "FlowFormStakeholdersTabManager",
  props: {
    codigoApi: { // caso o componente esteja disabled, não será possível incluir interessados e portando a api de busca será dispensável
      default: null,
      type: Function,
    },
    contas: {
      default: null,
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    idFluxo: {
      default: null,
      type: String,
    },
    interessados: {
      default: () => [],
      type: Array,
    },
    isFluxoIniciado: {
      default: false,
      type: Boolean,
    },
    isSequencial: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
