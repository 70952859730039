export const MODES = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

export function isAddMode(mode) {
  return isMode(mode, MODES.ADD);
}

export function isEditMode(mode) {
  return isMode(mode, MODES.EDIT);
}

export function isViewMode(mode) {
  return isMode(mode, MODES.VIEW);
}

function isMode(mode, targetMode) {
  if (typeof mode !== "string") return false;
  return mode.toLowerCase() === targetMode.toLowerCase();
}
