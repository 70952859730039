<template>
  <RowForm>
    <ColBase cols="4">
      <BInputGroup prepend="+">
        <BFormInput
          v-model="userCountryNumber"
          readonly
        />
      </BInputGroup>
    </ColBase>
    <ColBase cols="8">
      <BFormInput
        :id="camelCase($attrs.id, 'national')"
        v-model="userNationalNumber"
        :readonly="disabled"
        v-bind="$attrs"
      />
    </ColBase>
  </RowForm>
</template>

<script>
import { BFormInput, BInputGroup } from "@/lib/components/bv";
import { ColBase, RowForm } from "@/lib/components/layout";
import { decodePhone } from "@/lib/phones";
import { camelCase } from "@/lib/strings";

export default {
  components: { BFormInput, BInputGroup, ColBase, RowForm },
  computed: {
    userFullNumber() {
      if (!this.userNationalNumber) return null;
      return this.userCountryNumber + this.userNationalNumber;
    },
  },
  data() {
    return {
      userCountryNumber: "55",
      userNationalNumber: null,
    };
  },
  methods: {
    camelCase,
    handleNewCountry() {
      this.updateValue();
    },
    handleNewUserNationalNumber(newNationalNumber) {
      const newPhone = this.userFullNumber
        ? decodePhone(`+${this.userFullNumber}`)
        : null;
      if (!newPhone) return this.updateValue();

      const newFormattedNationalNumber = newPhone.formattedNationalNumber;
      if (newFormattedNationalNumber !== newNationalNumber) {
        // o usuário digitou algo novo mas que não está formatado. então jogamos o formato em tela e atualizamos o valor full to telefone.
        this.userNationalNumber = newFormattedNationalNumber;
      }
      this.updateValue();
    },
    handleNewValue(newValue) {
      const newPhone = newValue ? decodePhone(`+${newValue}`) : null;

      const newCountryNumber = newPhone ? newPhone.countryNumber : "55";
      const newNationalNumber = newPhone
        ? newPhone.formattedNationalNumber
        : null;

      if (newCountryNumber !== this.userCountryNumber) {
        this.userCountryNumber = newCountryNumber;
      }
      if (newNationalNumber !== this.userNationalNumber) {
        this.userNationalNumber = newNationalNumber;
      }
    },
    updateValue() {
      let newFullNumber = null;
      if (this.userFullNumber) {
        const phone = decodePhone(`+${this.userFullNumber}`);
        newFullNumber = phone ? phone.fullNumber : this.userFullNumber;
      }
      if (newFullNumber !== this.value) {
        this.$emit("input", newFullNumber);
      }
    },
  },
  name: "InputCelular",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    // usado como instrumento para setar programaticamente (após um request por exemplo) o telefone. recebe qualquer formatação válida, inclusive valores inválidos.
    value: {
      default: null,
      type: String,
    },
  },
  watch: {
    userCountryNumber: "handleNewCountry",
    userNationalNumber: "handleNewUserNationalNumber",
    value: {
      handler: "handleNewValue",
      immediate: true,
    },
  },
};
</script>
