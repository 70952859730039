import { asArray } from "@/lib/aggregates";
import { onMounted } from "vue";

export function useCollectionWithLookup({
  collection,
  filterMatch,
  filterMin = 10,
  initialMin = 100,
  initialValues,
}) {
  const initialConditions = resolveInitialConditions({ min: initialMin, values: initialValues });
  onMounted(() => {
    collection.queryUntilConditions(initialConditions);
  });

  return {
    handleRequest: createHandleRequest({ collection, match: filterMatch, min: filterMin }),
    isBusy: collection.isQuerying,
    options: collection.list,
  };
}

function createHandleRequest({ collection, match, min }) {
  return (filter) => {
    if (typeof filter === "string") {
      collection.queryUntilConditions([{
        incremental: true,
        match: match(filter),
        min,
      }]);
      return;
    }
    collection.queryNext();
  };
}

function resolveInitialConditions({ min, values }) {
  const initialConditions = [{ min }];
  asArray(values).forEach((match) => {
    initialConditions.push({ match });
  });
  return initialConditions;
}
