var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PanelButtons", {
        scopedSlots: _vm._u([
          {
            key: "start",
            fn: function () {
              return [
                _c("InfoText", [
                  _vm._v(
                    "\n        Pessoas que deverão responder ao(s) documento(s) do fluxo.\n      "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                !_vm.disabled
                  ? _c("FlowFormStakeholdersTabManagerAttachButton", {
                      attrs: {
                        "is-sequencial": _vm.isSequencial,
                        interessados: _vm.interessados,
                        "codigo-api": _vm.codigoApi,
                        contas: _vm.contas,
                      },
                      on: {
                        "update:interessados": function ($event) {
                          return _vm.$emit("update:interessados", $event)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "ListPower",
        _vm._b(
          {
            attrs: {
              field: "interessados",
              items: _vm.interessados,
              fields: _vm.fields,
              "initial-sort": _vm.initialSort,
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function ({ item, index }) {
                  return [
                    _c("FlowFormStakeholdersTabManagerButtonLink", {
                      attrs: {
                        field: index,
                        "id-fluxo": _vm.idFluxo,
                        "is-fluxo-iniciado": _vm.isFluxoIniciado,
                        interessado: item,
                      },
                    }),
                    _vm._v(" "),
                    _c("FlowFormStakeholdersTabManagerButtonsOrder", {
                      attrs: {
                        field: index,
                        interessados: _vm.interessados,
                        interessado: item,
                        hide: _vm.disabled || !_vm.isSequencial,
                      },
                      on: {
                        "update:interessados": function ($event) {
                          return _vm.$emit("update:interessados", $event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("FlowFormStakeholdersTabManagerButtonDetach", {
                      attrs: {
                        field: index,
                        "is-sequencial": _vm.isSequencial,
                        interessados: _vm.interessados,
                        interessado: item,
                        hide: _vm.disabled,
                      },
                      on: {
                        "update:interessados": function ($event) {
                          return _vm.$emit("update:interessados", $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          },
          "ListPower",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }