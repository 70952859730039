import { VueMaskDirective } from "v-mask";
import Vue from "vue";

export function installInputMasksDirective() {
  Vue.directive("mask", VueMaskDirective);
}

// https://github.com/probil/v-mask
const supportedMaskLiterals = [
  "#", // number (0-9)
  "A", // letter in any case (a-z,A-Z)
  "N", // number or letter (a-z,A-Z,0-9)
  "X", // any symbol
  "?", // optional (next character)
];

export function replaceMaskTokens(mask, placeholderToken) {
  return supportedMaskLiterals.reduce((acc, token) => {
    return acc.replaceAll(token, placeholderToken);
  }, mask);
}

export function extractRawValue(maskedValue, mask) {
  if (!mask || !maskedValue) return maskedValue;

  const literals = extractMaskLiterals(mask);
  const raw = literals.reduce((acc, literal) => {
    return acc.replaceAll(literal, "");
  }, maskedValue.trim());
  return raw;
}

export function extractMaskLiterals(mask) {
  const mixedLiterals = Array.from(new Set([...mask]));
  const maskLiterals = mixedLiterals.filter(t => !supportedMaskLiterals.includes(t));
  return maskLiterals;
}
