import { createCollectionStoreModule } from "@/lib";

export function createRequisitantesStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addRequester({ dispatch }, userRequester) {
        const responseRequester = await endpoints.requesterAdd.dispatch(userRequester);
        await dispatch("upsertItemFromRemote", responseRequester);
      },

      async editRequester({ dispatch }, userRequester) {
        const responseRequester = await endpoints.requesterEdit.dispatch(userRequester);
        await dispatch("upsertItemFromRemote", responseRequester);
      },

      async testRequesterWebhook(_, requesterId) {
        const testResponse = await endpoints.requesterWebhookTest.dispatch(requesterId);
        return testResponse;
      },
    },
    cacheEndpoints: {
      read: endpoints.requestersCacheRead,
      replace: endpoints.requestersCacheReplace,
      upsert: endpoints.requestersCacheUpsert,
    },
    getters: {
      currentRequisitante(state, getters, rootState, rootGetters) {
        const codigo = rootGetters["auth/codigo"];
        const currentRequisitante = getters.getItemByFieldValue("codigo", codigo);
        return currentRequisitante;
      },
      currentRequisitanteGrupos(state, getters) {
        const currentRequisitanteId = getters.currentRequisitanteId;
        return getters.requisitanteGrupos(currentRequisitanteId);
      },
      currentRequisitanteId(state, getters) {
        return getters.currentRequisitante
          ? getters.currentRequisitante.id
          : null;
      },
      requisitanteGrupos:
        (state, getters, rootState, rootGetters) => (requisitanteId) => {
          if (!requisitanteId) return [];

          const requisitante = getters.getItemById(requisitanteId);
          const gruposIds = requisitante ? requisitante.grupos : [];
          const grupos = rootGetters["grupos/filterItemsByFieldWithSomeValue"]("id", gruposIds);

          return grupos;
        },
    },
    pollingSeconds,
    queryEndpoint: endpoints.requestersList,
  });
}
