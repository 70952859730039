var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ControlLookup, {
    attrs: {
      value: _vm.value,
      disabled: _vm.disabled,
      options: _setup.options,
      "is-busy": _setup.isBusy,
      field: "gestores",
      label: "Gestores",
      multi: "",
      placeholder: "Escolha os demais gestores desse grupo",
      rules: "required",
      "text-field": "nome",
      "value-field": "id",
    },
    on: {
      input: function ($event) {
        return _setup.emit("input", $event)
      },
      request: _setup.handleRequest,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }