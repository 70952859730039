<template>
  <BOverlay :show="isBusy">
    <slot />
  </BOverlay>
</template>

<script>
import { BOverlay } from "@/lib/components/bv";

export default {
  components: { BOverlay },
  name: "OverlayBase",
  props: {
    isBusy: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
