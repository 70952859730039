export async function queryUntilConditions({ context, payload: conditions = [] }) {
  try {
    context.queryCount.value = context.queryCount.value + 1;

    const normalizedConditions = normalizeConditions({ conditions, context });

    let result = context.filterItems(normalizedConditions);
    while (!result.met && !context.isRemoteExhausted.value) {
      await context.queryNext();
      result = context.filterItems(normalizedConditions);
    }
    return result;
  }
  finally {
    context.queryCount.value = Math.max(context.queryCount.value - 1, 0);
  }
}

function normalizeConditions({ conditions, context }) {
  return conditions.map((condition) => {
    let min = condition.min ?? 1;
    if (condition.incremental) {
      const { items } = context.filterItems(condition);
      const existing = items.length;
      min += existing;
    }
    return { ...condition, min };
  });
}
