var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.OverlayBase,
    { attrs: { "is-busy": _setup.isSearchingCodigo } },
    [
      _c(
        _setup.GapperBase,
        [
          _setup.isAddMode(_setup.props.mode)
            ? _c(_setup.AlertText, {
                attrs: {
                  text: "O Tipo e o Código do usuário não poderão ser alterados no futuro",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            _setup.RowForm,
            [
              _c(
                _setup.ColBase,
                { attrs: { sm: "4" } },
                [
                  _c(_setup.ControlSelectSingle, {
                    attrs: {
                      field: _setup.camelCase("tipoCodigo", _vm.field),
                      value: _vm.value.tipoCodigo,
                      options: ["CPF", "GENERICO"],
                      disabled: !_setup.areIdentityFieldsEnabled,
                      label: "tipo",
                    },
                    on: {
                      input: function ($event) {
                        return _setup.handleEmitUpdate({ tipoCodigo: $event })
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _setup.ColBase,
                { attrs: { sm: "8" } },
                [
                  _c(_setup.ControlInput, {
                    attrs: {
                      field: _setup.camelCase("codigo", _vm.field),
                      value: _vm.value.codigo,
                      rules: `required${
                        _vm.value.tipoCodigo === "CPF" ? "|cpf" : ""
                      }`,
                      mask:
                        _vm.value.tipoCodigo === "CPF"
                          ? "###.###.###-##"
                          : null,
                      disabled: !_setup.areIdentityFieldsEnabled,
                      label: "código",
                      "lookup-options": _setup.lookupOptions,
                      "lookup-text-field": "codigo",
                      "lookup-value-field": "id",
                    },
                    on: {
                      lookup: _setup.handleLookup,
                      input: function ($event) {
                        return _setup.handleEmitUpdate({ codigo: $event })
                      },
                      blur: _setup.handleCodigoSearch,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _setup.isAddMode(_setup.props.mode) &&
          _vm.value.tipoCodigo === "GENERICO"
            ? _c(_setup.AlertText, {
                attrs: {
                  variant: "info",
                  text: "Usuário do tipo GENERICO não pode assinar fluxos que requeiram uso de certificado ICP-Brasil",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            _setup.RowForm,
            [
              _c(
                _setup.ColBase,
                { attrs: { sm: "" } },
                [
                  _c(_setup.ControlInput, {
                    attrs: {
                      field: _setup.camelCase(_vm.field, "nome"),
                      value: _vm.value.nome,
                      disabled: !_setup.areIdentityFieldsEnabled,
                      rules: "required|min:3|max:255",
                      label: "Nome",
                      "lookup-options": _setup.lookupOptions,
                      "lookup-text-field": "nome",
                      "lookup-value-field": "id",
                    },
                    on: {
                      lookup: _setup.handleLookup,
                      input: function ($event) {
                        return _setup.handleEmitUpdate({ nome: $event })
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            _setup.RowForm,
            [
              _c(
                _setup.ColBase,
                { attrs: { sm: "" } },
                [
                  _c(_setup.ControlEmail, {
                    attrs: {
                      value: _vm.value.email,
                      field: _setup.camelCase(_vm.field, "email"),
                      disabled: !_setup.areContactFieldsEnabled,
                      rules: "required",
                      label: _vm.emailLabel,
                      "lookup-options": _setup.lookupOptions,
                      "lookup-text-field": "email",
                      "lookup-value-field": "id",
                    },
                    on: {
                      lookup: _setup.handleLookup,
                      input: function ($event) {
                        return _setup.handleEmitUpdate({ email: $event })
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _setup.ColBase,
                { attrs: { sm: "" } },
                [
                  _c(_setup.ControlCelular, {
                    attrs: {
                      value: _vm.value.celular,
                      field: _setup.camelCase(_vm.field, "celular"),
                      disabled: !_setup.areContactFieldsEnabled,
                      label: _vm.celularLabel,
                    },
                    on: {
                      input: function ($event) {
                        return _setup.handleEmitUpdate({ celular: $event })
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }