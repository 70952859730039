import { AppError, SYSLOG_SEVERITIES } from "@/lib";

// ativa código legado de comunicação com assinador desktop
import "./desktop-integration";

const maybeWindows = navigator.userAgent.toLowerCase().includes("win");
export const ALGORITMO_DESKTOP = maybeWindows ? "SHA-256" : "SHA-512";

export const CONNECTION_STATUSES = {
  CONNECTED: "CONNECTION_STATUSES.CONNECTED",
  DISCONNECTED: "CONNECTION_STATUSES.DISCONNECTED",
  STARTING: "CONNECTION_STATUSES.STARTING",
  UNAUTHORIZED: "CONNECTION_STATUSES.UNAUTHORIZED",
};

export async function attemptConnection() {
  const isLoaded = window.SerproSignerClient;
  if (!isLoaded) return CONNECTION_STATUSES.STARTING;

  const isRunning = await verifyDesktopClientInstallation();
  if (!isRunning) return CONNECTION_STATUSES.DISCONNECTED;

  const isConnected = window.SerproSignerClient.isConnected();
  if (isConnected) return CONNECTION_STATUSES.CONNECTED;

  attemptToConfigureDesktopClient();
  await connectToWebSocket();
  const isConnectedAfterAfterAttempt = window.SerproSignerClient.isConnected();

  return isConnectedAfterAfterAttempt
    ? CONNECTION_STATUSES.CONNECTED
    : CONNECTION_STATUSES.UNAUTHORIZED;
}

function verifyDesktopClientInstallation() {
  return new Promise((resolve) => {
    window.SerproSignerClient.verifyIsInstalledAndRunning()
      .success(() => resolve(true))
      .error(() => resolve(false));
  });
}

function attemptToConfigureDesktopClient() {
  try {
    window.SerproSignerClient.setDebug(true);
    window.SerproSignerClient.setUriServer("wss", "127.0.0.1", 65156, "/signer");
  }
  catch {
    // não é levantado exceção pois a situação é tratada no fluxo de execução com mensagem ao usuário
  }
}

function connectToWebSocket() {
  return new Promise((resolve) => {
    const onOpenClose = connectionStatus => resolve(connectionStatus === 1);
    const onError = () => resolve(false);
    window.SerproSignerClient.connect(onOpenClose, onOpenClose, onError);
  });
}

export function signDocumentsHashes(hashes) {
  const hashPack = Array.isArray(hashes) ? hashes.join(" ") : hashes;

  return new Promise((resolve, reject) => {
    window.SerproSignerClient.sign("hash", hashPack)
      .success((response) => {
        if (response.actionCanceled) {
          // o assinador serpro trata o cancelamento pelo usuário como uma exceção e comumente gera uma exceção que não passa nem por esse código de tratamento.
          const message = "O usuário cancelou a assinatura pelo Assinador Desktop Serpro";
          return reject(new AppError({ message, severity: SYSLOG_SEVERITIES.WARNING }));
        }
        resolve(response.signature.split(" "));
      })
      .error((response) => {
        // cenário mais comum desse trecho é um erro levantado pelo assinador serpro quando o usuário cancela manualmente a operação de assinatura, mas como não há uma codificação ou tipificação dos erros optou-se aqui por continuar levantando a exceção.
        reject(new AppError({ cause: response, message: "Ocorreu um erro. Verifique o Assinador SERPRO ou se o Token está inserido.", severity: SYSLOG_SEVERITIES.ERROR }));
      });
  });
}
