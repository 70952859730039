import { asArray } from "@/lib/aggregates";

export function filterItems({ context, payload: conditions }) {
  const items = context.items.value;
  const uniqueItems = new Map();

  const normalizedConditions = asArray(conditions);

  const met = normalizedConditions.every((condition) => {
    const { match, min = 1 } = condition;

    const filteredItems = !match
      ? Object.values(items)
      : typeof match === "function"
        ? Object.values(items).filter(match)
        : asArray(items[match]);

    filteredItems.forEach(item => uniqueItems.set(item.id, item));

    return filteredItems.length >= min;
  });

  return {
    items: Array.from(uniqueItems.values()),
    met,
  };
}
