import { Module } from "./module";

export class Store {
  constructor(modules) {
    Object.entries(modules).forEach(([name, options]) => {
      const module = new Module({ store: this, ...options });
      this[name] = module;
    });
  }

  reset() {
    const modules = Object
      .values(this)
      .filter(module => module instanceof Module);
    modules.forEach(module => module.reset());
  }
}
