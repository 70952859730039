import { Api } from "../base";
import { LocalStorageEndpoint } from "./endpoint";

export class LocalStorageApi extends Api {
  key;

  constructor({ key, ...options }) {
    super(options);
    this.key = key;
  }

  performCreateEndpoint(options) {
    return new LocalStorageEndpoint({ key: this.key, ...options });
  }
}
