<script setup>
import { computed } from "vue";

const props = defineProps({
  lines: {
    default: 10,
    type: Number,
  },
});

const lineWidths = computed(() => {
  const widths = [];
  for (let i = 0; i < props.lines; i++) {
    const patterns = [100, 75, 50];
    widths.push(patterns[i % patterns.length]);
  }
  return widths;
});
</script>
<template>
  <div class="skeleton-loader">
    <div
      v-for="(width, index) in lineWidths"
      :key="index"
      class="skeleton-line"
      :style="{ width: width + '%' }"
    />
  </div>
</template>
<style scoped>
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: var(--w-size-10);
}

/* Shimmer effect */
.skeleton-line {
  height: var(--w-size-35);
  background: linear-gradient(to right,
      var(--w-color-neutral-30) 8%,
      var(--w-color-neutral-20) 18%,
      var(--w-color-neutral-30) 33%);
  background-size: 800px 100%;
  animation: shimmer 7s infinite linear;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}
</style>
