<script setup>
import { SkeletonLoader } from "@/lib/async";
import { NavBar, NavCrumb, SideBar } from "@/lib/components/navs";
import { CardBase } from "@/lib/components/surfaces";
import { computed, ref } from "vue";

const props = defineProps({
  busy: {
    default: false,
    type: [Boolean, String],
    validator(value) {
      if (value === null || value === undefined) return true;
      if (typeof value === "boolean") return true;
      return ["idle", "progress", "skeleton", "spinner"].includes(value);
    },
  },
  crumbs: {
    required: true,
    type: Array,
  },
  headerText: {
    required: true,
    type: String,
  },
  noCard: {
    default: false,
    type: Boolean,
  },
  sideLinks: {
    required: true,
    type: Array,
  },
});

const showSideBar = ref(false);
const normalizedBusy = computed(() => {
  if (!props.busy) return "idle";
  if (props.busy === true) return "progress";
  return props.busy;
});
</script>
<template>
  <div class="dashboard-page">
    <NavBar
      :is-open="showSideBar"
      @update:isOpen="showSideBar = $event"
    >
      <template #logo>
        <slot name="navStart" />
      </template>
      <template #links>
        <slot name="navEnd" />
      </template>
    </NavBar>

    <SideBar
      id="navSidebar"
      :links="sideLinks"
      :is-open="showSideBar"
      @update:isOpen="showSideBar = $event"
    />
    <div class="p-2 p-sm-5">
      <NavCrumb
        data-test="nav-crumbs"
        :path="crumbs"
        class="d-none d-sm-flex"
      />
      <CardBase
        :header-text="headerText"
        :show-overlay="normalizedBusy === 'spinner'"
        :show-linear="normalizedBusy === 'progress'"
        :transparent="noCard"
        :tight="noCard"
      >
        <main>
          <template v-if="normalizedBusy === 'skeleton'">
            <SkeletonLoader />
          </template>
          <template v-else>
            <!-- o template oculta apresentação de conteúdo em paginas de edição, por exemplo, essa tática evita ativar regras de formulário ou outros elementos da ui antes que os dados de negócio iniciais estejam carregados. entretanto é preciso ficar atento ao fato que qulquer estado mantido dentro de componentes pode ser perdido se a expressão for invalidada e validada novamente  -->
            <slot
              name="content"
            />
          </template>
        </main>
      </CardBase>
    </div>
  </div>
</template>
<style scoped>
.dashboard-page {
  background-color: var(--w-color-neutral-20);
  min-height: 100vh;
}
</style>
