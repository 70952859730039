import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

export const reservedNames = ["actions", "state", "store", "patch", "reset", "plugins"];

export function throwIfReservedName(name) {
  if (reservedNames.includes(name)) {
    throw new AppError({
      message: `${name} is a reserved name`,
      severity: SYSLOG_SEVERITIES.CRITICAL,
    });
  }
}
