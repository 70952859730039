var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ControlSelectSingle",
    _vm._g(
      _vm._b(
        {
          attrs: {
            field: "prazoAlertaRespostaPendente",
            options: _vm.intervals,
            "value-field": "id",
            "text-field": "nome",
            rules: "required",
            label: "Intervalo entre avisos",
            "helper-text":
              "Automaticamente o sistema irá notificar o usuário no momento em que o fluxo é iniciado e no dia anterior a data limite para resposta do fluxo.",
          },
          model: {
            value: _vm.selectedOption,
            callback: function ($$v) {
              _vm.selectedOption = $$v
            },
            expression: "selectedOption",
          },
        },
        "ControlSelectSingle",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }