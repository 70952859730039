var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ControlCheckbox, {
    attrs: {
      value: _vm.value,
      field: "data-limite",
      disabled: _vm.disabled,
      placeholder: "Definir data limite para resposta.",
      "helper-text": "O assinante terá uma data limite para assinatura.",
      switch: "",
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }