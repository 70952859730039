<script setup>
import { ButtonClose } from "@/lib/components/buttons";
import { AppIcon } from "@/lib/components/icons";
import { SYSLOG_SEVERITIES } from "@/lib/severities";
import { computed } from "vue";

const props = defineProps({
  notification: {
    required: true,
    type: Object,
  },
});

const icon = computed(() => {
  switch (props.notification.severity) {
    case SYSLOG_SEVERITIES.EMERGENCY:
    case SYSLOG_SEVERITIES.ALERT:
    case SYSLOG_SEVERITIES.CRITICAL:
    case SYSLOG_SEVERITIES.ERROR: {
      return "error";
    }
    case SYSLOG_SEVERITIES.WARNING: {
      return "alert";
    }
    case SYSLOG_SEVERITIES.NOTICE:
    case SYSLOG_SEVERITIES.INFO:
    case SYSLOG_SEVERITIES.DEBUG: {
      return "info";
    }
    case SYSLOG_SEVERITIES.SUCCESS: {
      return "success";
    }
    default: {
      return "info";
    }
  }
});

function handleClick() {
  props.notification.discard();
}
</script>
<template>
  <div class="notifications-toast">
    <div class="notifications-toast__icon-column">
      <AppIcon
        :name="icon"
        font-scale="3"
      />
    </div>
    <div class="notifications-toast__message-column">
      <p>{{ notification.message }}</p>
    </div>
    <div class="notifications-toast__button-column">
      <ButtonClose @click="handleClick" />
    </div>
  </div>
</template>
<style scoped>
.notifications-toast {
  position: relative;
  overflow: hidden;
  padding: var(--w-size-20);

  background-color: var(--w-color-neutral-10);
  border: var(--w-border-neutral);

  font-size: var(--w-font-size-20);

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: var(--w-size-30);
  align-items: center;
}

.notifications-toast__icon-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-toast__message-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.notifications-toast__button-column {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
</style>
