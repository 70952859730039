var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.DialogForm, {
    attrs: {
      "default-payload": _vm.defaultInteressado,
      "external-errors": _vm.externalErrors,
      "external-errors-field-map": { codigo: "codigoInteressado" },
      "show-action-divider": "",
    },
    on: {
      save: function ($event) {
        return _vm.$emit("save", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function ({ payload, patch }) {
            return [
              _c(_setup.AccountDialog, {
                ref: "accountDialog",
                attrs: {
                  value: payload,
                  "codigo-api": _vm.codigoApi,
                  contas: _vm.contas,
                  mode: _vm.mode,
                  field: "interessado",
                },
                on: { input: patch },
              }),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [
              _vm._t("actions", null, {
                clearAccountData: () => _setup.accountDialog.reset(),
              }),
              _vm._v(" "),
              _c(_setup.ButtonsSaveBack, {
                on: {
                  back: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }