import { createApi } from "@/lib";

export function createBackendApi({ environment, getToken }) {
  const environmentBaseUrl = environment.get("WEB_API_BASE_URL");
  const baseUrl = environmentBaseUrl ?? resolveBaseUrl();
  const api = createApi({ baseUrl, tokenOrGetter: getToken, type: "http" });
  return api;
}

function resolveBaseUrl() {
  let origin = window.location.origin;

  // esse hostname é o percebido pelos tests e2e rodando dentro do container
  if (origin.includes("web-app")) origin = "http://api-gateway:8080";

  return `${origin}/api/`;
}
