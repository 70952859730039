<script setup>
import { isArrayFull } from "@/lib/aggregates";
import { usePopover } from "@/lib/components/dialogs";
import { kebabCase } from "@/lib/strings";
import { computed, ref, toRefs } from "vue";

import DisplayValue from "./display-value.vue";
import DropdownSurface from "./dropdown-surface.vue";
import InputFilter from "./input-filter.vue";
import ListSelected from "./list-selected.vue";
import ListUnselected from "./list-unselected.vue";
import { useOptions } from "./use-options";

const props = defineProps({
  add: {
    default: false,
    type: Boolean,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  id: {
    required: true,
    type: String,
  },
  isBusy: {
    default: false,
    type: Boolean,
  },
  multi: {
    default: false,
    type: Boolean,
  },
  options: {
    default: () => [],
    type: Array,
  },
  placeholder: {
    default: "Clique para selecionar",
    type: String,
  },
  rows: {
    default: 5,
    type: Number,
  },
  state: {
    default: null,
    type: Boolean,
  },
  textField: {
    default: "text",
    type: String,
  },
  value: {
    default: null,
    type: [Array, String],
  },
  valueField: {
    default: "value",
    type: String,
  },
});
const emit = defineEmits(["input", "request"]);

const { disabled, multi, options, textField, valueField } = toRefs(props);
const model = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit("input", value);
  },
});
const filter = ref();

const parentId = kebabCase(props.id);
const { anchorBox, anchorProperties, hidePopover, popoverProperties } = usePopover(parentId);
const { handleAdded, handleSelected, handleUnselected, selected, unselected } = useOptions({ disabled, filter, model, multi, options, textField, valueField });

function handleRequest() {
  emit("request", filter.value);
}

function handleAndHide(handler, option) {
  handler(option);
  if (!multi.value) {
    hidePopover();
  }
}
</script>
<template>
  <div
    :id="parentId"
    class="input-lookup"
  >
    <DisplayValue
      :selected="selected"
      v-bind="anchorProperties"
      :disabled="disabled"
      :is-busy="isBusy"
      :state="state"
      :placeholder="placeholder"
    />
    <DropdownSurface
      v-if="!disabled || isArrayFull(selected)"
      v-bind="popoverProperties"
      :rows="rows"
      :anchor-box="anchorBox"
      :is-busy="isBusy"
      @contentEnd="handleRequest"
    >
      <template
        v-if="!disabled"
        #static
      >
        <InputFilter
          :id="kebabCase(parentId, 'filter')"
          v-model="filter"
          :add="add"
          @input="handleRequest"
          @added="handleAndHide(handleAdded, $event)"
        />
        <hr
          v-if="isArrayFull(selected) || isArrayFull(unselected)"
          class="input-dropdown__divider"
        >
      </template>
      <template #scrollable>
        <ListSelected
          :id="kebabCase(parentId, 'selected')"
          :disabled="disabled"
          :options="selected"
          @toggle="handleUnselected"
        />
        <hr
          v-if="isArrayFull(selected) && isArrayFull(unselected)"
          class="input-dropdown__divider"
        >
        <ListUnselected
          :id="kebabCase(parentId, 'unselected')"
          :disabled="disabled"
          :options="unselected"
          @toggle="handleAndHide(handleSelected, $event)"
        />
      </template>
    </DropdownSurface>
  </div>
</template>
<style scoped>
.input-lookup {
  --input-lookup-font-size: var(--w-font-size-30);
}

.input-lookup {
  position: relative;
  width: 100%;

  font-size: var(--input-lookup-font-size);
  font-weight: var(--w-font-weight-20);
}

.input-dropdown__divider {
  margin-block: var(--w-size-20);
}
</style>
