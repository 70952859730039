export function decodeGroup(backendGroup) {
  const { ativo, descricao, gestores, id, nome, preferencias: { tipoAssinatura }, requisitantes } = backendGroup;

  const standardApprovalBlocked = !tipoAssinatura.permiteAlteracao;
  const standardApprovalIsIcp = tipoAssinatura.tipo === "QUALIFICADA";

  return { ativo, descricao, gestores, id, nome, requisitantes, standardApprovalBlocked, standardApprovalIsIcp };
}

export function encodeGroup(frontendGroup) {
  const { ativo = true, descricao, gestores = [], id, nome, standardApprovalBlocked, standardApprovalIsIcp } = frontendGroup;

  const preferencias = {
    tipoAssinatura: {
      permiteAlteracao: !standardApprovalBlocked,
      tipo: standardApprovalIsIcp ? "QUALIFICADA" : "AVANCADA",
    },
  };

  return { ativo, descricao, gestores, id, nome, preferencias };
}
