import { onMounted, ref } from "vue";

export function useMountWithConditions({ conditions, module, onMet, onUnmet }) {
  const querying = ref(true);

  onMounted(async () => {
    try {
      const result = await module.queryUntilConditions(conditions);
      if (result.met) {
        onMet && onMet(result.items);
      }
      else {
        onUnmet && onUnmet(result.items);
      }
    }
    finally {
      querying.value = false;
    }
  });

  return { querying };
}
