import { Endpoint } from "../base";
import { METHODS } from "./methods";

export class LocalStorageEndpoint extends Endpoint {
  key;
  method;

  constructor({ key, method, ...options }) {
    super(options);
    this.key = key;
    this.method = method;

    if (!Object.values(METHODS).includes(method))
      throw new Error(`method ${method} not supported`);
  }

  performDispatch(payload) {
    switch (this.method) {
      case METHODS.GET:
        return JSON.parse(localStorage.getItem(this.key));
      case METHODS.REMOVE:
        localStorage.removeItem(this.key);
        break;
      case METHODS.SET:
        localStorage.setItem(this.key, JSON.stringify(payload));
        break;
    }
  }
}
