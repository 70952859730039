import { clone } from "@/lib/aggregates";

export function createBaseStoreModule(config) {
  const { actions, getters, mutations, state } = config;

  const initialState = clone(state);

  const storeModule = {
    actions,
    getters,
    mutations: {
      resetState(state, newState = initialState) {
        Object.assign(state, clone(newState));
      },
      ...mutations,
    },
    namespaced: true,
    state: clone(initialState),
  };

  return storeModule;
}
