import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

import { useExecute } from "./use-execute";

export function useSaga(options) {
  const { logger, retry: userRetry = false, saga } = typeof options === "function" ? { saga: options } : options;

  if (typeof saga !== "function") AppError.throw({
    message: "useSaga requires a saga function",
    severity: SYSLOG_SEVERITIES.CRITICAL,
  });

  const { execute: executeBase, isBusy, lastError, lastResult } = useExecute({ logger, retry: userRetry });

  const execute = async payload => executeBase(saga, payload);

  return { execute, isBusy, lastError, lastResult };
}
