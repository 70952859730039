var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlInput", {
                attrs: {
                  rules: "required|max:15",
                  label: "Sigla",
                  field: "sigla",
                  disabled: !_vm.isNew,
                },
                model: {
                  value: _vm.payload.sigla,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "sigla", $$v)
                  },
                  expression: "payload.sigla",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlInput", {
                attrs: {
                  rules: "required",
                  label: "Nome",
                  field: "nome",
                  disabled: !_vm.isNew,
                },
                model: {
                  value: _vm.payload.nome,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "nome", $$v)
                  },
                  expression: "payload.nome",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlInput", {
                attrs: {
                  field: "cnpj",
                  rules: "required|cnpj",
                  mask: "NN.NNN.NNN/NNNN-##",
                  disabled: !_vm.isNew,
                  label: "CNPJ",
                },
                model: {
                  value: _vm.payload.cnpj,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "cnpj", $$v)
                  },
                  expression: "payload.cnpj",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlCheckbox", {
                attrs: {
                  placeholder: "Habilitar uso do Biovalid",
                  field: "biovalid",
                  switch: "",
                },
                model: {
                  value: _vm.payload.biovalid,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "biovalid", $$v)
                  },
                  expression: "payload.biovalid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isNew
        ? _c(
            "RowForm",
            [
              _c(
                "ColBase",
                [
                  _c("ControlCheckbox", {
                    attrs: {
                      placeholder: "Ativar Cliente",
                      field: "ativo",
                      switch: "",
                    },
                    model: {
                      value: _vm.payload.ativo,
                      callback: function ($$v) {
                        _vm.$set(_vm.payload, "ativo", $$v)
                      },
                      expression: "payload.ativo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }