import { computed, readonly, ref } from "vue";

export function useAsyncSearch(searchApi) {
  const isSearching = ref(false);
  const searchResult = ref(null);

  // sucesso e falha não são o oposto uma da outra porque a busca pode ainda não ter acontecido ou estar acontecendo
  const searchFailed = computed(() => searchResult.value?.status === "fail");
  const searchSucceeded = computed(() => searchResult.value?.status === "success");

  const search = async (searchToken) => {
    if (!searchToken) return;

    // "não é possível realizar uma nova pesquisa enquanto outra ainda está em execução",
    if (isSearching.value) return;

    isSearching.value = true;
    searchResult.value = null;

    try {
      const data = await searchApi(searchToken);

      if (!data || Object.keys(data).length === 0) {
        throw Error("force catch clause");
      }

      searchResult.value = {
        data,
        status: "success",
      };
    }
    catch (error) {
      searchResult.value = { status: "fail" };
    }
    finally {
      isSearching.value = false;
    }

    return searchResult;
  };

  const resetSearchState = () => {
    isSearching.value = false;
    searchResult.value = null;
  };

  return {
    isSearching: readonly(isSearching),
    resetSearchState,
    search,
    searchFailed,
    searchResult: readonly(searchResult),
    searchSucceeded,
  };
}
