<script setup>
import { createDefaultStakeholder } from "@/data";
import { ButtonsSaveBack, DialogForm, MODES } from "@/lib";
import { AccountDialog } from "@/views/widgets/account-dialog";
import { ref } from "vue";

defineProps({
  codigoApi: {
    default: null,
    type: Function,
  },
  contas: {
    default: null,
    type: Array,
  },
  defaultInteressado: {
    default: createDefaultStakeholder,
    type: Object,
  },
  externalErrors: {
    default: null,
    type: [Object, Error],
  },
  mode: {
    default: MODES.VIEW,
    type: String,
  },
});

const accountDialog = ref(null);
</script>
<template>
  <DialogForm
    :default-payload="defaultInteressado"
    :external-errors="externalErrors"
    :external-errors-field-map="{ codigo: 'codigoInteressado' }"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload, patch }">
      <AccountDialog
        ref="accountDialog"
        :value="payload"
        :codigo-api="codigoApi"
        :contas="contas"
        :mode="mode"
        field="interessado"
        @input="patch"
      />
    </template>
    <template #actions>
      <slot
        name="actions"
        :clear-account-data="() => accountDialog.reset()"
      />
      <ButtonsSaveBack @back="$emit('back')" />
    </template>
  </DialogForm>
</template>
