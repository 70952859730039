import { inject, provide } from "@/lib/use";

const key = "$logger";

export function provideLogger(logger) {
  provide({ instance: logger, key });
}

export function injectLogger() {
  return inject(key);
}
