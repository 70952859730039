var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.BFormGroup,
    {
      staticClass: "control-group",
      attrs: { "label-class": "p-0", description: _vm.helperText },
      scopedSlots: _vm._u(
        [
          _vm.label
            ? {
                key: "label",
                fn: function () {
                  return [
                    _c(_setup.LabelBase, {
                      attrs: { text: _vm.label, for: _vm.controlId },
                    }),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(_setup.BFormInvalidFeedback, { attrs: { state: _vm.state } }, [
        _vm._v("\n    " + _vm._s(_vm.error) + "\n  "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }