<script setup>
import { asArray, sortByField } from "@/lib/aggregates";
import { BFormTag, BFormTags } from "@/lib/components/bv";
import { kebabCase } from "@/lib/strings";
import { computed, ref } from "vue";

import { InputBase } from "../control-input";

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  hide: {
    default: false,
    type: Boolean,
  },
  id: {
    required: true,
    type: String,
  },
  options: {
    required: true,
    type: Array,
  },
  placeholder: {
    default: null,
    type: String,
  },
  rows: {
    default: 3,
    type: Number,
  },
  state: {
    default: null,
    type: Boolean,
  },
  textField: {
    required: true,
    type: String,
  },
  value: {
    default: () => [],
    type: Array,
  },
  valueField: {
    required: true,
    type: String,
  },
});
const emit = defineEmits(["input"]);

const baseId = computed(() => kebabCase("input-multi", props.id));

// o componente de input base depende de ter um v-model configurado para funcione corretamente mesmo que não seja usado nas funcionalidades do input multi
const inputBaseValue = ref("");

const selectedOptionsValues = computed(() => {
  return sortedOptions.value
    .filter(opt => asArray(props.value).includes(opt[props.valueField]))
    .map(opt => opt[props.valueField]);
});

const sortedOptions = computed(() => {
  return sortByField(props.options, { field: props.textField });
});

const unselectedOptions = computed(() => {
  return sortedOptions.value.filter(opt => !asArray(props.value).includes(opt[props.valueField]));
});

const inputDisabled = computed(() => {
  return props.disabled || unselectedOptions.value.length === 0;
});

function handleInputBaseLookup(lookupOption) {
  if (!lookupOption) return;
  emit("input", asArray(props.value).concat(lookupOption[props.valueField]));
}

function isTagDisabled(tag) {
  const option = props.options.find(o => o[props.valueField] === tag);
  if (!option) return false;
  return option.disabled;
}

function tagText(tag) {
  const option = props.options.find(o => o[props.valueField] === tag);
  if (!option || !option[props.textField]) return "";

  return option[props.textField];
}
</script>

<template>
  <div
    :id="baseId"
    class="input-multi"
  >
    <InputBase
      :id="kebabCase(baseId, 'input')"
      v-model="inputBaseValue"
      :lookup-options="unselectedOptions"
      :lookup-text-field="textField"
      :lookup-value-field="valueField"
      :disabled="inputDisabled"
      @lookup="handleInputBaseLookup"
    />
    <BFormTags
      v-if="!hide"
      :id="kebabCase(baseId, 'tags')"
      :state="state"
      :value="selectedOptionsValues"
      :disabled="disabled"
      remove-on-delete
      add-on-change
      no-outer-focus
      tag-variant="primary"
      class="input-multi-tags"
      v-on="$listeners"
    >
      <template
        #default="{
          tags,
          disabled: tagsDisabled,
          removeTag,
        }"
      >
        <p
          v-if="tags.length === 0"
          class="mt-2"
        >
          {{ placeholder }}
        </p>
        <ul
          v-else
          class="list-inline d-inline-block mt-2"
        >
          <li
            v-for="tag in tags"
            :key="tag"
            class="list-inline-item"
          >
            <BFormTag
              :id="kebabCase(baseId, 'tags', tagText(tag))"
              :title="tag"
              variant="secondary"
              pill
              :disabled="tagsDisabled || isTagDisabled(tag)"
              @remove="removeTag(tag)"
            >
              {{ tagText(tag) }}
            </BFormTag>
          </li>
        </ul>
      </template>
    </BFormTags>
  </div>
</template>

<style scoped>
.input-multi {
  padding: var(--w-size-20);
  border: var(--w-border-neutral);
}

.input-multi-tags {
  border: none !important;
}
</style>
