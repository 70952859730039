import {
  createAuthRoute,
  createChangelogRoute,
  createClientsAddRoute,
  createClientsEditRoute,
  createClientsListRoute,
  createDesignRoute,
  createFlowsArchivedApprovalsRoute,
  createFlowsArchivedListRoute,
  createFlowsArchivedViewRoute,
  createFlowsEngagementAcknowledgedRoute,
  createFlowsEngagementApprovalRoutes,
  createFlowsEngagementApprovalsRoute,
  createFlowsEngagementDesktopRoute,
  createFlowsEngagementFeedbackRoute,
  createFlowsEngagementListRoute,
  createFlowsEngagementSerproIdRoutes,
  createFlowsManagementAddRoute,
  createFlowsManagementApprovalsRoute,
  createFlowsManagementEditRoute,
  createFlowsManagementListRoute,
  createFlowsManagementViewRoute,
  createGroupsAddRoute,
  createGroupsEditRoute,
  createGroupsListRoute,
  createGroupsMembersListRoute,
  createGroupsViewRoute,
  createLogsAddRoute,
  createLogsListRoute,
  createLogsViewRoute,
  createMaintenanceRoute,
  createManagersAddRoute,
  createManagersEditRoute,
  createManagersListRoute,
  createNotFoundRoute,
  createRequestersAddRoute,
  createRequestersEditRoute,
  createRequestersListRoute,
  createRequestersWebhookRoute,
  createStakeholdersAddRoute,
  createStakeholdersEditRoute,
  createStakeholdersListRoute,
} from "@/views/pages";

export function createRoutes({ serproIdBaseUrl, serproIdClientId, serproIdRedirectPath }) {
  const authRoute = createAuthRoute();
  const notFoundRoute = createNotFoundRoute();

  return [
    { path: "/", redirect: authRoute.path },

    authRoute,

    notFoundRoute,
    createMaintenanceRoute(),
    createDesignRoute(),

    createChangelogRoute(),

    createClientsListRoute(),
    createClientsAddRoute(),
    createClientsEditRoute(),

    createFlowsArchivedListRoute(),
    createFlowsArchivedViewRoute(),
    createFlowsArchivedApprovalsRoute(),

    createFlowsEngagementListRoute(),
    createFlowsEngagementAcknowledgedRoute(),
    createFlowsEngagementApprovalsRoute(),
    ...createFlowsEngagementApprovalRoutes(),
    createFlowsEngagementFeedbackRoute(),
    createFlowsEngagementDesktopRoute(),
    ...createFlowsEngagementSerproIdRoutes({ serproIdBaseUrl, serproIdClientId, serproIdRedirectPath }),

    createFlowsManagementListRoute(),
    createFlowsManagementAddRoute(),
    createFlowsManagementApprovalsRoute(),
    createFlowsManagementEditRoute(),
    createFlowsManagementViewRoute(),

    createGroupsAddRoute(),
    createGroupsEditRoute(),
    createGroupsListRoute(),
    createGroupsMembersListRoute(),
    createGroupsViewRoute(),

    createManagersListRoute(),
    createManagersEditRoute(),
    createManagersAddRoute(),

    createRequestersAddRoute(),
    createRequestersEditRoute(),
    createRequestersListRoute(),
    createRequestersWebhookRoute(),

    createStakeholdersAddRoute(),
    createStakeholdersEditRoute(),
    createStakeholdersListRoute(),

    createLogsAddRoute(),
    createLogsListRoute(),
    createLogsViewRoute(),

    { path: "*", redirect: notFoundRoute.path },
  ];
}
