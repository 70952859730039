import { createCollectionStoreModule } from "@/lib";

export function createGestoresStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addManager({ dispatch }, userManager) {
        const responseManager = await endpoints.managerAdd.dispatch(userManager);
        await dispatch("upsertItemFromRemote", responseManager);
      },

      async editManager({ dispatch }, userManager) {
        const responseManager = await endpoints.managerEdit.dispatch(userManager);
        await dispatch("upsertItemFromRemote", responseManager);
      },
    },
    cacheEndpoints: {
      read: endpoints.managersCacheRead,
      replace: endpoints.managersCacheReplace,
      upsert: endpoints.managersCacheUpsert,
    },
    pollingSeconds,
    queryEndpoint: endpoints.managersList,
  });
}
