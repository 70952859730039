<template>
  <FragmentBase>
    <RowForm>
      <ColBase>
        <ControlInput
          v-model="payload.sigla"
          rules="required|max:15"
          label="Sigla"
          field="sigla"
          :disabled="!isNew"
        />
      </ColBase>
    </RowForm>
    <RowForm>
      <ColBase>
        <ControlInput
          v-model="payload.nome"
          rules="required"
          label="Nome"
          field="nome"
          :disabled="!isNew"
        />
      </ColBase>
    </RowForm>
    <RowForm>
      <ColBase>
        <ControlInput
          v-model="payload.cnpj"
          field="cnpj"
          rules="required|cnpj"
          mask="NN.NNN.NNN/NNNN-##"
          :disabled="!isNew"
          label="CNPJ"
        />
      </ColBase>
    </RowForm>
    <RowForm>
      <ColBase>
        <ControlCheckbox
          v-model="payload.biovalid"
          placeholder="Habilitar uso do Biovalid"
          field="biovalid"
          switch
        />
      </ColBase>
    </RowForm>
    <RowForm v-if="!isNew">
      <ColBase>
        <ControlCheckbox
          v-model="payload.ativo"
          placeholder="Ativar Cliente"
          field="ativo"
          switch
        />
      </ColBase>
    </RowForm>
  </FragmentBase>
</template>

<script>
import { ColBase, ControlCheckbox, ControlInput, FragmentBase, RowForm } from "@/lib";

export default {
  components: {
    ColBase,
    ControlCheckbox,
    ControlInput,
    FragmentBase,
    RowForm,
  },
  name: "ClientFields",
  props: {
    isNew: {
      default: false,
      type: Boolean,
    },
    payload: {
      default: () => ({}),
      type: Object,
    },
  },
};
</script>
