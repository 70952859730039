import { decodeFlow, encodeFlow } from "./code-flow";

export function createFlowsEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }) {
  const flowsUrl = "/v1/fluxos";

  return {
    flowAdd: backendApi.createEndpoint({
      decode: decodeFlow,
      encode: encodeFlow,
      method: "post",
      url: flowsUrl,
    }),

    flowArchive: backendApi.createEndpoint({
      decode: decodeFlow,
      method: "patch",
      url: flowId => `${flowsUrl}/${flowId}/arquivar`,
    }),

    flowCancel: backendApi.createEndpoint({
      decode: decodeFlow,
      method: "patch",
      url: flowId => `${flowsUrl}/${flowId}/cancelar`,
    }),

    flowEdit: backendApi.createEndpoint({
      decode: decodeFlow,
      encode: encodeFlow,
      method: "put",
      url: ({ id }) => `${flowsUrl}/${id}`,
    }),

    flowFinish: backendApi.createEndpoint({
      decode: decodeFlow,
      method: "patch",
      url: flowId => `${flowsUrl}/${flowId}/finalizar`,
    }),

    flowHashes: backendApi.createEndpoint({
      method: "get",
      url: ({ algorithm, flowId }) => `${flowsUrl}/${flowId}/hashes-documentos/${algorithm}`,
    }),

    flowLinkSend: backendApi.createEndpoint({
      encode: ({ idFluxo, idRequisitante, interessado }) => ({ idFluxo, idRequisitante, interessados: [interessado] }),
      method: "patch",
      url: "/v1/reenviar-links",
    }),

    flowReject: backendApi.createEndpoint({
      decode: decodeFlow,
      method: "patch",
      url: flowId => `${flowsUrl}/${flowId}/rejeitar`,
    }),

    flowReviewDraft: backendApi.createEndpoint({
      encode: ({ feedback, flowId }) => ({ ...feedback, id: flowId }),
      method: "patch",
      url: ({ id }) => `${flowsUrl}/${id}/revisao`,
    }),

    flowReviewSubmit: backendApi.createEndpoint({
      encode: ({ feedback, flowId }) => ({ ...feedback, id: flowId }),
      method: "patch",
      url: ({ id }) => `${flowsUrl}/${id}/revisar`,
    }),

    flowSignAdvanced: backendApi.createEndpoint({
      decode: decodeFlow,
      method: "patch",
      url: flowId => `${flowsUrl}/${flowId}/assinar`,
    }),

    flowSignSerproId: backendApi.createEndpoint({
      decode: decodeFlow,
      method: "patch",
      url: ({ flowId }) => `${flowsUrl}/${flowId}/assinar-serpro-id`,
    }),

    flowSignSerproSigner: backendApi.createEndpoint({
      decode: decodeFlow,
      encode: ({ documents, ...rest }) => ({ documentos: documents, ...rest }),
      method: "patch",
      url: ({ algorithm, flowId }) => `${flowsUrl}/${flowId}/assinar-desktop/${algorithm}`,
    }),

    flowStart: backendApi.createEndpoint({
      decode: decodeFlow,
      method: "patch",
      url: flowId => `${flowsUrl}/${flowId}/iniciar`,
    }),

    flowsArchivedCacheRead: cacheApi.createEndpoint({
      method: "read",
      store: cacheApiSchema.stores.flowsArchived,
    }),

    flowsArchivedCacheReplace: cacheApi.createEndpoint({
      method: "replace",
      store: cacheApiSchema.stores.flowsArchived,
    }),

    flowsArchivedCacheUpsert: cacheApi.createEndpoint({
      method: "upsert",
      store: cacheApiSchema.stores.flowsArchived,
    }),

    flowsArchivedList: backendApi.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      method: "get",
      url: page => `${flowsUrl}/arquivados?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),

    flowsEngagementList: backendApi.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      method: "get",
      url: page => `/v1/respostas?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),

    flowsManagementList: backendApi.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      method: "get",
      url: page => `${flowsUrl}?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),
  };
}
