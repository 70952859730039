export async function queryNext({ context }) {
  if (context.isRemoteExhausted.value) return;

  let page = 0;
  while (context.queriedPages.value.has(page)) {
    page++;
  }

  await context.queryPage(page);
}
