import { Store } from "@/lib";

import { createClients } from "../clients-unified";
import { createManagers } from "../managers-unified";

export function initUnifiedStore({ endpoints, onAuthChange }) {
  const managers = createManagers(endpoints);
  const clients = createClients(endpoints);

  const unifiedStore = new Store({ clients, managers });

  onAuthChange(() => unifiedStore.reset());

  return unifiedStore;
}
