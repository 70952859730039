import { uuid } from "@/lib/crypto";
import { SYSLOG_SEVERITIES } from "@/lib/severities";
import { ref } from "vue";

export class NotificationsService {
  constructor() {
    this.notifications = ref([]);
  }

  discard(id) {
    const index = this.notifications.value.findIndex(n => n.id === id);
    if (index !== -1) {
      this.notifications.value.splice(index, 1);
    }
  }

  list() {
    return this.notifications.value;
  }

  notify(notification = {}) {
    const id = uuid();
    const discard = () => this.discard(id);

    this.notifications.value.unshift({
      ...notification,
      discard,
      id,
    });

    if (notification.delayInSeconds) {
      setTimeout(() => discard(), notification.delayInSeconds * 1000);
    }

    return discard;
  }

  toast(notification = {}) {
    return this.notify({
      delayInSeconds: 5,
      type: "toast",
      ...notification,
    });
  }

  toastError(notification = {}) {
    return this.toast({
      severity: SYSLOG_SEVERITIES.ERROR,
      ...notification,
    });
  }

  toastSuccess(notification = {}) {
    return this.toast({
      severity: SYSLOG_SEVERITIES.SUCCESS,
      ...notification,
    });
  }
}
