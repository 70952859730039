import { createBaseStoreModule } from "@/lib";

export function createContasStore({ endpoints }) {
  return createBaseStoreModule({
    actions: {
      search(_, code) {
        return endpoints.accountByCode.dispatch(code);
      },
    },
    getters: {
      getUniqueActiveContas: (state, getters, rootState, rootGetters) => {
        const uniqueAccounts = new Map();

        const stakeholders = getActiveItemsFromModule("interessados");
        addToAccounts(stakeholders);

        const managers = getActiveItemsFromModule("gestores");
        addToAccounts(managers);

        const requesters = getActiveItemsFromModule("requisitantes");
        addToAccounts(requesters);

        return Array.from(uniqueAccounts.values());

        function getActiveItemsFromModule(module) {
          return rootGetters[`${module}/filterItemsByFieldValue`]("ativo", true);
        }

        function addToAccounts(list) {
          for (const account of list) {
            uniqueAccounts.set(account.codigo, account);
          }
        }
      },
    },
  });
}
