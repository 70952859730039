<template>
  <ControlWrapper
    :control-component="InputCheckbox"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { ControlWrapper } from "../control-wrapper";
import InputCheckbox from "./input-checkbox";

export default {
  components: { ControlWrapper },
  data() {
    return {
      InputCheckbox,
    };
  },
  name: "ControlCheckbox",
};
</script>
