export function createDefaultFlow(currentRequesterId = null) {
  return {
    dadosBasicos: {
      idRequisitante: currentRequesterId,
      status: "CRIADO",
    },
    documentos: [],
    interessados: [],
  };
}
