var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalBase, {
    ref: "modal",
    attrs: { size: "lg" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function ({ save, cancel }) {
          return [
            _c(_setup.DialogForm, {
              attrs: {
                "default-payload": _setup.defaultInteressado,
                "show-action-divider": "",
              },
              on: {
                save: function ($event) {
                  return save($event)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "body",
                    fn: function ({ payload, patch }) {
                      return [
                        _c(
                          _setup.RowForm,
                          [
                            _c(
                              _setup.ColBase,
                              [
                                _c(_setup.ControlSelectSingle, {
                                  attrs: {
                                    field: "papel",
                                    options: ["ASSINANTE", "REVISOR"],
                                    rules: "required",
                                    label: "Papel",
                                  },
                                  model: {
                                    value: payload.papel,
                                    callback: function ($$v) {
                                      _vm.$set(payload, "papel", $$v)
                                    },
                                    expression: "payload.papel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(_setup.AccountDialog, {
                          ref: "accountDialog",
                          attrs: {
                            value: payload,
                            "codigo-api": _vm.codigoApi,
                            contas: _vm.contas,
                            field: "interessado",
                            mode: _setup.MODES.ADD,
                          },
                          on: { input: patch },
                        }),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(_setup.ButtonTertiary, {
                          attrs: { field: "reset", label: "Limpar" },
                          on: { click: _setup.handleReset },
                        }),
                        _vm._v(" "),
                        _c(_setup.ButtonsSaveBack, {
                          on: {
                            back: function ($event) {
                              return cancel()
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }