import { throwIfReservedName } from "./names";

export class Plugins {
  container;
  plugins;

  constructor({ container = {}, options = [] } = {}) {
    options.forEach(throwIfReservedName);

    this.container = container;
    this.plugins = options;
  }

  reduceActions() {
    return this.plugins.reduce((acc, plugin) => {
      return {
        ...acc,
        ...plugin.actions ?? {},
      };
    }, {});
  }

  reduceState() {
    return this.plugins.reduce((acc, plugin) => {
      return {
        ...acc,
        ...plugin.state ?? {},
      };
    }, {});
  }
}
