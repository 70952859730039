var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.TabAccordion,
    _vm._b(
      {
        attrs: {
          field: "dados",
          "header-title": "Detalhes",
          "header-subtitle": _vm.fluxo.dadosBasicos.status,
          "header-icon-name": "data",
        },
      },
      "TabAccordion",
      { ..._vm.$attrs },
      false
    ),
    [
      _c(
        _setup.GapperBase,
        [
          _c(
            _setup.RowForm,
            [
              _c(
                _setup.ColBase,
                [
                  _c(_setup.ControlInput, {
                    attrs: {
                      field: "nome",
                      disabled: _vm.disabled,
                      rules: "required|min:3|max:100",
                      label: "Nome",
                    },
                    model: {
                      value: _vm.fluxo.dadosBasicos.nome,
                      callback: function ($$v) {
                        _vm.$set(_vm.fluxo.dadosBasicos, "nome", $$v)
                      },
                      expression: "fluxo.dadosBasicos.nome",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _setup.ColBase,
                { attrs: { sm: "" } },
                [
                  _c(_setup.FlowFormBasicTabControlGroup, {
                    attrs: { disabled: _vm.disabled },
                    model: {
                      value: _vm.fluxo.dadosBasicos.idGrupo,
                      callback: function ($$v) {
                        _vm.$set(_vm.fluxo.dadosBasicos, "idGrupo", $$v)
                      },
                      expression: "fluxo.dadosBasicos.idGrupo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(_setup.flowFormBasicTabControlInterval, {
            attrs: { disabled: _vm.disabled },
            model: {
              value: _vm.fluxo.dadosBasicos.prazoAlertaRespostaPendente,
              callback: function ($$v) {
                _vm.$set(
                  _vm.fluxo.dadosBasicos,
                  "prazoAlertaRespostaPendente",
                  $$v
                )
              },
              expression: "fluxo.dadosBasicos.prazoAlertaRespostaPendente",
            },
          }),
          _vm._v(" "),
          _c(_setup.ControlInput, {
            attrs: {
              value: _vm.flowRequester.nome,
              field: "requisitante",
              disabled: "",
              label: "Criado por:",
            },
          }),
          _vm._v(" "),
          _c(_setup.ControlTextarea, {
            attrs: {
              field: "descricao",
              disabled: _vm.disabled,
              rules: "required|min:3|max:1000",
              label: "Descrição",
            },
            model: {
              value: _vm.fluxo.dadosBasicos.descricao,
              callback: function ($$v) {
                _vm.$set(_vm.fluxo.dadosBasicos, "descricao", $$v)
              },
              expression: "fluxo.dadosBasicos.descricao",
            },
          }),
          _vm._v(" "),
          _c(_setup.FlowFormBasicTabControlData, {
            attrs: {
              disabled: _vm.disabled,
              "group-id": _vm.fluxo.dadosBasicos.idGrupo,
              "flow-status": _vm.fluxo.dadosBasicos.status,
            },
            on: { input: _setup.onChangeLigado },
            model: {
              value: _setup.ligadoLocal,
              callback: function ($$v) {
                _setup.ligadoLocal = $$v
              },
              expression: "ligadoLocal",
            },
          }),
          _vm._v(" "),
          _setup.ligadoLocal
            ? _c("div", { staticStyle: { "margin-top": "1rem" } }, [
                _c("label", { attrs: { for: "data" } }, [
                  _vm._v("Data limite:"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.localDataLimite,
                      expression: "localDataLimite",
                    },
                  ],
                  attrs: { id: "data", type: "date", min: _setup.minDate },
                  domProps: { value: _setup.localDataLimite },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _setup.localDataLimite = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(_setup.FlowFormBasicTabControlIcp, {
            attrs: {
              "group-id": _vm.fluxo.dadosBasicos.idGrupo,
              disabled: _vm.disabled,
              "flow-status": _vm.fluxo.dadosBasicos.status,
            },
            model: {
              value: _vm.fluxo.dadosBasicos.isIcp,
              callback: function ($$v) {
                _vm.$set(_vm.fluxo.dadosBasicos, "isIcp", $$v)
              },
              expression: "fluxo.dadosBasicos.isIcp",
            },
          }),
          _vm._v(" "),
          _c(_setup.FlowFormBasicTabControlSequencial, {
            attrs: {
              "is-sequencial": _vm.fluxo.dadosBasicos.isSequencial,
              "is-interrompe-se-negativo":
                _vm.fluxo.dadosBasicos.isInterrompeSeNegativo,
              interessados: _vm.fluxo.interessados,
              disabled: _vm.disabled,
            },
            on: {
              "update:isSequencial": function ($event) {
                return _vm.$set(_vm.fluxo.dadosBasicos, "isSequencial", $event)
              },
              "update:is-sequencial": function ($event) {
                return _vm.$set(_vm.fluxo.dadosBasicos, "isSequencial", $event)
              },
              "update:isInterrompeSeNegativo": function ($event) {
                return _vm.$set(
                  _vm.fluxo.dadosBasicos,
                  "isInterrompeSeNegativo",
                  $event
                )
              },
              "update:is-interrompe-se-negativo": function ($event) {
                return _vm.$set(
                  _vm.fluxo.dadosBasicos,
                  "isInterrompeSeNegativo",
                  $event
                )
              },
              "update:interessados": function ($event) {
                return _vm.$set(_vm.fluxo, "interessados", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }