import { isObjectLike } from "@/lib/aggregates";
import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

import { Endpoint } from "../base";

export class HttpEndpoint extends Endpoint {
  baseUrl;
  endpointUrl;
  headers;
  method;
  responseType;
  tokenOrGetter;

  constructor({
    baseUrl,
    contentType,
    headers,
    method,
    responseType,
    tokenOrGetter,
    url,
    ...options
  }) {
    super(options);
    this.baseUrl = baseUrl;
    this.tokenOrGetter = tokenOrGetter;
    this.headers = headers ?? {};
    this.method = method?.toUpperCase() ?? "GET";
    this.responseType = responseType?.toLowerCase() ?? "json";
    this.endpointUrl = url;
    this.contentType = contentType?.toLowerCase() ?? "application/json";
  }

  async #resolveErrorData(response = {}) {
    const { status, statusText, url } = response;

    let data = {};
    if (response) {
      try {
        data = await response.json();
      }
      catch { /* ignore parse error */ }
    }

    const code = data.code || status;

    // statusText também poderia ser considerado como alternativa de mensagem mas foi abandonado por ser normalmente um texto técnico em inglês
    const message = data.message || "Aconteceu um erro inesperado na aplicação durante a requisição.";

    const severity = status < 500 ? SYSLOG_SEVERITIES.WARNING : SYSLOG_SEVERITIES.ERROR;
    const meta = {
      ...data,
      isHttp: true,
      requestUrl: url,
      status,
      statusText,
    };

    return { code, message, meta, severity };
  }

  #resolvePayloadHeadersAndBody(payload) {
    const empty = { body: undefined, headers: {} };

    if (this.contentType === "none") return empty;
    if (this.method === "GET") return empty;
    if (!isObjectLike(payload)) return empty;

    if (this.contentType === "multipart/form-data") {
      const formData = new FormData();
      for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value);
      }
      return { body: formData, headers: {} };
    }

    return { body: JSON.stringify(payload), headers: { "Content-Type": this.contentType } };
  }

  async #resolveResponse(response) {
    // 204 No Content e 205 Reset Content não possuem conteúdo
    if ([204, 205].includes(response.status)) {
      return null;
    }

    switch (this.responseType) {
      case "arraybuffer":
        return response.arrayBuffer();
      case "blob":
        return response.blob();
      case "text":
        return response.text();
      default:
        return response.json();
    }
  }

  #resolveTokenHeaders() {
    const token = typeof this.tokenOrGetter === "function"
      ? this.tokenOrGetter()
      : this.tokenOrGetter;
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  #resolveUrl(payload) {
    // alguns endpoints podem ser dinâmicos como edições em que o id é passado no payload
    const relativeOrFullUrl = typeof this.endpointUrl === "function"
      ? this.endpointUrl(payload)
      : this.endpointUrl;

    let fullUrl = relativeOrFullUrl;
    if (this.baseUrl && !/^https?:\/\//.test(relativeOrFullUrl)) {
      const path = relativeOrFullUrl.startsWith("/") ? relativeOrFullUrl.substring(1) : relativeOrFullUrl;
      fullUrl = new URL(path, this.baseUrl).toString();
    }
    return fullUrl;
  }

  async performDispatch(payload) {
    const fullUrl = this.#resolveUrl(payload);
    const tokenHeaders = this.#resolveTokenHeaders();
    const { body, headers: payloadHeaders } = this.#resolvePayloadHeadersAndBody(payload);

    const fetchOptions = {
      body,
      headers: {
        ...this.headers,
        ...tokenHeaders,
        ...payloadHeaders,
      },
      method: this.method,
    };

    let response;
    try {
      response = await fetch(fullUrl, fetchOptions);
      if (!response.ok) {
        throw new Error(response.statusText ?? "request failed");
      }
    }
    catch {
      const errorData = await this.#resolveErrorData(response);
      AppError.throw(errorData);
    }

    try {
      const result = await this.#resolveResponse(response);
      return result;
    }
    catch (cause) {
      const errorData = await this.#resolveErrorData(response);
      throw AppError.throw({ ...errorData, cause });
    }
  }
}
