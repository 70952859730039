var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.DashboardPageBase,
    { attrs: { crumbs: _vm.crumbs, busy: _setup.busy } },
    [
      _c(_setup.DialogForm, {
        attrs: {
          "default-payload": _vm.defaultPayload,
          "external-errors": _vm.externalErrors,
          "external-errors-field-map": _vm.externalErrorsFieldMap,
          "show-action-divider": "",
        },
        on: {
          save: function ($event) {
            return _vm.$emit("action", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function ({ payload }) {
                return [_vm._t("body", null, { payload: payload })]
              },
            },
            {
              key: "actions",
              fn: function () {
                return [_vm._t("actions")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }