<script setup>
import { ControlLookup, injectUnifiedStore, stringIncludes, useCollectionWithLookup } from "@/lib";

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  value: {
    default: null,
    type: [Array, String],
  },
});
const emit = defineEmits(["input"]);

const { managers } = injectUnifiedStore();
const { handleRequest, isBusy, options } = useCollectionWithLookup({
  collection: managers,
  filterMatch: filter => ({ nome }) => stringIncludes(nome, filter),
  initialValues: props.value,
});
</script>
<template>
  <ControlLookup
    :value="value"
    :disabled="disabled"
    :options="options"
    :is-busy="isBusy"
    field="gestores"
    label="Gestores"
    multi
    placeholder="Escolha os demais gestores desse grupo"
    rules="required"
    text-field="nome"
    value-field="id"
    @input="emit('input', $event)"
    @request="handleRequest"
  />
</template>
