export function validateCnpj(maybeCnpj) {
  if (typeof maybeCnpj !== "string") return false;

  const notAllowedChars = /[^A-Z\d./-]/i;
  if (notAllowedChars.test(maybeCnpj)) return false;

  const mask = /[./-]/g;
  const cnpjWithoutMask = maybeCnpj.replace(mask, "");

  const blockedList = ["00000000000000"];
  if (blockedList.includes(cnpjWithoutMask)) return false;

  const allowedChars = /^([A-Z\d]){12}(\d){2}$/;
  if (!allowedChars.test(cnpjWithoutMask)) return false;

  const sizeWithoutDv = 12;
  const inputDv = cnpjWithoutMask.substring(sizeWithoutDv);

  const baseValue = "0".charCodeAt(0);
  const weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const cnpjWithoutDv = cnpjWithoutMask.substring(0, sizeWithoutDv);
  let dv1Sum = 0;
  let dv2Sum = 0;
  for (let i = 0; i < sizeWithoutDv; i++) {
    const asciiDigit = cnpjWithoutDv.charCodeAt(i) - baseValue;
    dv1Sum += asciiDigit * weights[i + 1];
    dv2Sum += asciiDigit * weights[i];
  }
  const dv1 = dv1Sum % 11 < 2 ? 0 : 11 - (dv1Sum % 11);
  dv2Sum += dv1 * weights[sizeWithoutDv];
  const dv2 = dv2Sum % 11 < 2 ? 0 : 11 - (dv2Sum % 11);
  const calcDv = `${dv1}${dv2}`;

  return calcDv === inputDv;
}
