var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DashboardLegacyPageBase",
    {
      attrs: {
        "related-store-modules": _vm.collectionsStoreModules,
        crumbs: _vm.crumbs,
        "header-text": _vm.headerText,
      },
    },
    [
      _c(
        "div",
        { attrs: { id: _vm.camelCase("list", "page", _vm.field) } },
        [
          _c(
            "PanelButtons",
            [
              _vm.collectionsStoreModules
                ? _c("ButtonSecondary", {
                    attrs: { field: "update", label: "Atualizar" },
                    on: { click: _vm.handleUpdate },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("listActions"),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ListPower",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    field: _vm.field,
                    items: _vm.items,
                    "primary-key": _vm.primaryKey,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function ({ item, index }) {
                          return [
                            _vm._t("listItemActions", null, {
                              index: index,
                              item: item,
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                "ListPower",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }