import { assertEffects } from "./effects";
import { createLog } from "./log";
import { enablePersistence } from "./persistence";
import { createTrace } from "./trace";

export function createLogger({ effects: userEffects = [], meta: defaultMeta = {}, size = 10, storageKey = "LOGGER" } = {}) {
  const { clear, list, save } = enablePersistence({ size, storageKey });

  const effects = assertEffects([save, ...userEffects]);
  const log = createLog({ effects, meta: defaultMeta });
  const trace = createTrace({ effects, meta: defaultMeta });

  const logger = { clear, list, log, trace };

  return logger;
}
