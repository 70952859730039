import { Api } from "../base";
import { HttpEndpoint } from "./endpoint";

export class HttpApi extends Api {
  baseUrl;
  tokenOrGetter;

  constructor({ baseUrl, tokenOrGetter, ...options }) {
    super(options);
    this.baseUrl = baseUrl;
    this.tokenOrGetter = tokenOrGetter;
  }

  performCreateEndpoint(options) {
    return new HttpEndpoint({
      ...options,
      baseUrl: this.baseUrl,
      tokenOrGetter: this.tokenOrGetter,
    });
  }
}
