import { Endpoint } from "../base";

export class ResolverEndpoint extends Endpoint {
  resolver;

  constructor({ resolver, ...options }) {
    super(options);

    if (typeof resolver !== "function") {
      throw new Error("resolver must be a function");
    }

    this.resolver = resolver;
  }

  performDispatch(payload) {
    return this.resolver(payload);
  }
}
