<script setup>
import { FLOW_STATUSES } from "@/data";
import { AlertText, injectLegacyStore } from "@/lib";
import { ApprovalIcp } from "@/views/widgets/approval-icp";
import { computed, watch } from "vue";

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  flowStatus: {
    default: null,
    type: String,
  },
  groupId: {
    default: null,
    type: String,
  },
  value: {
    default: false,
    type: Boolean,
  },
});
const emit = defineEmits(["input"]);

const store = injectLegacyStore("store");

const group = computed(() => {
  if (!props.groupId) return null;
  return store.getters["grupos/getItemById"](props.groupId);
});

const standardApprovalBlocked = computed(() => {
  if (!group.value) return false;
  return group.value.standardApprovalBlocked;
});

const normalDisabled = computed(() => {
  if (props.disabled) return true;
  if (standardApprovalBlocked.value) return true;
  if (props.flowStatus !== FLOW_STATUSES.CREATED) return true;
  return false;
});

watch(
  () => group.value,
  (currentGroup) => {
    if (!currentGroup) return;
    if (props.flowStatus !== FLOW_STATUSES.CREATED) return;
    emit("input", currentGroup.standardApprovalIsIcp);
  },
);

</script>
<template>
  <div>
    <AlertText
      v-if="standardApprovalBlocked"
      text="O gestor do grupo não permite alteração das configurações de assinatura"
    />
    <ApprovalIcp
      :disabled="normalDisabled"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>
