export class Endpoint {
  decode;
  dispatched = false;
  dispatching = false;
  encode;
  name;

  constructor({ decode, encode, name }) {
    this.name = name;
    this.decode = decode;
    this.encode = encode;
  }

  async dispatch(userPayload) {
    const payload = typeof this.encode === "function" ? this.encode(userPayload) : userPayload;
    try {
      this.dispatching = true;
      const result = await this.performDispatch(payload);
      return typeof this.decode === "function" ? this.decode(result) : result;
    }
    finally {
      this.dispatched = true;
      this.dispatching = false;
    }
  }

  performDispatch(payload) {
    // should be overrided by subclasses, code here is for testing purposes
    return Promise.resolve(payload);
  }
}
