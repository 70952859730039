import { render, staticRenderFns } from "./notifications-global.vue?vue&type=template&id=27b5a1fa&scoped=true"
import script from "./notifications-global.vue?vue&type=script&setup=true&lang=js"
export * from "./notifications-global.vue?vue&type=script&setup=true&lang=js"
import style0 from "./notifications-global.vue?vue&type=style&index=0&id=27b5a1fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b5a1fa",
  null
  
)

export default component.exports