import { CollectionPlugin } from "@/lib/stores";

import { fromEndpointToUnifiedPageDispatch } from "../helpers";

export function createManagers(endpoints) {
  const pageDispatch = fromEndpointToUnifiedPageDispatch(endpoints.managersList);
  return {
    actions: {
      async addManager({ context, payload: userManager }) {
        const responseManager = await endpoints.managerAdd.dispatch(userManager);
        context.setItem(responseManager);
      },
      async editManager({ context, payload: userManager }) {
        const responseManager = await endpoints.managerEdit.dispatch(userManager);
        context.setItem(responseManager);
      },
    },
    plugins: [new CollectionPlugin({ pageDispatch })],
  };
}
