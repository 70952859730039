<script setup>
import { DialogForm } from "@/lib";
import { computed } from "vue";

import { DashboardPageBase } from "../dashboard-page-base";

const props = defineProps({
  actionBusy: {
    default: false,
    type: Boolean,
  },
  crumbs: {
    required: true,
    type: Array,
  },
  defaultPayload: {
    default: () => ({}),
    type: Object,
  },
  externalErrors: {
    default: null,
    type: [Object, Error],
  },
  externalErrorsFieldMap: {
    default: () => ({}),
    type: Object,
  },
  mountBusy: {
    default: false,
    type: Boolean,
  },
});
defineEmits(["action"]);

const busy = computed(() => {
  return props.mountBusy
    ? "skeleton"
    : props.actionBusy
      ? "spinner"
      : "idle";
});
</script>
<template>
  <DashboardPageBase
    :crumbs="crumbs"
    :busy="busy"
  >
    <DialogForm
      :default-payload="defaultPayload"
      :external-errors="externalErrors"
      :external-errors-field-map="externalErrorsFieldMap"
      show-action-divider
      @save="$emit('action', $event)"
    >
      <template #body="{ payload }">
        <slot
          name="body"
          :payload="payload"
        />
      </template>
      <template #actions>
        <slot name="actions" />
      </template>
    </DialogForm>
  </DashboardPageBase>
</template>
