<template>
  <TabAccordion
    field="interessados"
    header-title="Interessados"
    header-icon-name="people"
    :header-icon-badge="howManyInteressados"
    v-bind="$attrs"
  >
    <FlowFormStakeholdersTabManager
      :interessados.sync="fluxo.interessados"
      :id-fluxo="fluxo.id"
      :is-fluxo-iniciado="isFluxoIniciado"
      :is-sequencial="fluxo.dadosBasicos.isSequencial"
      :disabled="disabled"
      :codigo-api="codigoApi"
      :contas="contas"
    />
  </TabAccordion>
</template>

<script>
import { extractInteressados, isIniciado } from "@/data";
import { TabAccordion } from "@/lib";

import FlowFormStakeholdersTabManager from "./flow-form-stakeholders-tab-manager";

export default {
  components: { FlowFormStakeholdersTabManager, TabAccordion },
  computed: {
    howManyInteressados() {
      const interessados = extractInteressados(this.fluxo);
      return interessados.length > 0 ? interessados.length : null;
    },
    isFluxoIniciado() {
      return isIniciado(this.fluxo);
    },
  },
  name: "FlowFormStakeholdersTab",
  props: {
    codigoApi: {
      default: null,
      type: Function,
    },
    contas: {
      default: null,
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    fluxo: {
      default: null,
      type: Object,
    },
  },
};
</script>
