<template>
  <ControlWrapper
    :control-component="controlComponent"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { ControlWrapper } from "../control-wrapper";
import InputTextarea from "./input-textarea";

export default {
  components: { ControlWrapper },
  data() {
    return {
      controlComponent: InputTextarea,
    };
  },
  name: "ControlTextarea",
};
</script>
