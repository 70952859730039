<template>
  <ControlWrapper
    :control-component="InputMulti"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { ControlWrapper } from "../control-wrapper";
import InputMulti from "./input-multi";

export default {
  components: { ControlWrapper },
  data() {
    return {
      InputMulti,
    };
  },
  name: "ControlSelectMulti",
};
</script>
