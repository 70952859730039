import { isArrayFull } from "@/lib/aggregates";

export function createQueryPage(pageDispatch) {
  return async ({ context, payload: pageToQuery }) => {
    try {
      context.queryCount.value = context.queryCount.value + 1;

      const { items: pageItems, total: remoteTotal } = await pageDispatch(pageToQuery);

      const isTotalRemoteKnown = remoteTotal !== null && remoteTotal !== undefined;
      if (isTotalRemoteKnown) {
        context.totalRemote.value = remoteTotal;
      }

      const isPageFull = isArrayFull(pageItems);
      const isTotalRemoteReached = isTotalRemoteKnown && remoteTotal <= context.total.value;
      context.isRemoteExhausted.value = !isPageFull || isTotalRemoteReached;

      if (isPageFull) {
        const manifest = pageItems.map(i => ({ operation: "set", payload: i }));
        context.setBatch(manifest);
      }

      context.queriedPages.value.add(pageToQuery);
    }
    finally {
      context.queryCount.value = Math.max(context.queryCount.value - 1, 0);
    }
  };
}
