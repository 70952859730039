import { createAccountsEndpoints } from "../accounts";
import { createAuthEndpoints } from "../auth";
import { createBackendApi } from "../backend-api";
import { cacheApiSchema, createCacheApi } from "../cache-api";
import { createChangelogEndpoints } from "../changelog";
import { createClientsEndpoints } from "../clients";
import { createDocumentsEndpoints } from "../documents";
import { createFlowsEndpoints } from "../flows";
import { createGroupsEndpoints } from "../groups";
import { createHelloEndpoint } from "../hello";
import { createwebLoggerEndpoint } from "../logger";
import { createManagersEndpoints } from "../managers";
import { createRequestersEndpoints } from "../requesters";
import { createStakeholdersEndpoints } from "../stakeholders";

export function initApisEndpoints({ environment, getAccountId, getToken }) {
  const pageLimit = environment.get("WEB_PAGINATION_LIMIT", 100);
  const backendApi = createBackendApi({ environment, getToken });
  const cacheApi = createCacheApi(getAccountId);

  return {
    hello: createHelloEndpoint(backendApi),
    logger: createwebLoggerEndpoint({ environment }),
    ...createAccountsEndpoints(backendApi),
    ...createAuthEndpoints(backendApi),
    ...createChangelogEndpoints(),
    ...createClientsEndpoints({ backendApi, pageLimit }),
    ...createDocumentsEndpoints(backendApi),
    ...createFlowsEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }),
    ...createGroupsEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }),
    ...createManagersEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }),
    ...createRequestersEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }),
    ...createStakeholdersEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }),
  };
}
