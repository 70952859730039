import { DEFAULT_SEVERITY, SYSLOG_SEVERITIES } from "@/lib/severities";

import { applyEffects } from "./effects";
import { createDefaultMeta } from "./meta";

export function createLog({ effects, meta: maybeDefaultMeta }) {
  const defaultMeta = createDefaultMeta(maybeDefaultMeta);

  return async (thisCallOptions) => {
    const { message, meta: thisCallMeta, severity } = decomposeThisCallOptions(thisCallOptions);

    const meta = {
      ...defaultMeta(),
      ...thisCallMeta,
    };

    await applyEffects({ effects, message, meta, severity });
  };
}

function decomposeThisCallOptions(userOptions) {
  if (typeof userOptions === "string")
    return { message: userOptions, meta: {}, severity: DEFAULT_SEVERITY };

  if (userOptions instanceof Error) {
    const { meta: innerMeta = {}, severity = SYSLOG_SEVERITIES.ERROR, ...metaInOptions } = userOptions;

    // message, name e stack não são desestruturados automaticamente e precisam ser acessados de forma explicita.
    return {
      message: userOptions.message,
      meta: { name: userOptions.name, stack: userOptions.stack, ...innerMeta, ...metaInOptions },
      severity: severity,
    };
  }

  // todos os campos extra usados na chamada são tratados como metadados. essa opção ao contrário de exigir um objeto meta especifico facilita que objetos personalizados possam ser passados diretamente ao logger e tudo que não é severity e message é automaticamente envelopado em meta.
  const { message, meta: innerMeta = {}, severity = DEFAULT_SEVERITY, ...metaInOptions } = userOptions;
  return { message, meta: { ...innerMeta, ...metaInOptions }, severity };
}
