import { inject, provide } from "@/lib/use";

import { key } from "./key";

export function provideLegacyStore(store) {
  provide({ instance: store, key });
}

export function injectLegacyStore() {
  return inject(key);
}
