<script setup>
import { ColBase, ControlInput, ControlTextarea, GapperBase, RowForm, TabAccordion } from "@/lib";
import { computed, ref, watch } from "vue";

import FlowFormBasicTabControlData from "./flow-form-basic-tab-control-data.vue";
import FlowFormBasicTabControlGroup from "./flow-form-basic-tab-control-group";
import FlowFormBasicTabControlIcp from "./flow-form-basic-tab-control-icp.vue";
import flowFormBasicTabControlInterval from "./flow-form-basic-tab-control-interval.vue";
import FlowFormBasicTabControlSequencial from "./flow-form-basic-tab-control-sequencial";

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  flowRequester: {
    required: true,
    type: Object,
  },
  fluxo: {
    required: true,
    type: Object,
  },
  ligado: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(["update:ligado"]);

// Variável reativa para o checkbox
const ligadoLocal = ref(props.ligado);

// Se o fluxo já vier com uma data válida, forçamos ligadoLocal para true
if (props.fluxo.dadosBasicos.dataLimiteResposta) {
  const dateObj = new Date(props.fluxo.dadosBasicos.dataLimiteResposta);
  if (!isNaN(dateObj.getTime())) {
    ligadoLocal.value = true;
  }
}

watch(
  () => props.ligado,
  (newVal) => {
    ligadoLocal.value = newVal;
  },
);

// Data atual para o atributo min do input
const today = new Date();
const minDate = computed(() => today.toISOString().split("T")[0]);

// Variável local para armazenar a data formatada
const localDataLimite = ref("");

// Inicializa a variável local com o valor do fluxo (se houver)
if (props.fluxo.dadosBasicos.dataLimiteResposta) {
  const dateObj = new Date(props.fluxo.dadosBasicos.dataLimiteResposta);
  if (!isNaN(dateObj.getTime())) {
    localDataLimite.value = dateObj.toISOString().split("T")[0];
  }
}

// Sincroniza: quando o valor local mudar, atualiza o fluxo
watch(localDataLimite, (novoValor) => {
  props.fluxo.dadosBasicos.dataLimiteResposta = novoValor;
});

// Sincroniza: se o fluxo mudar e vier com uma data válida, atualiza a variável local
watch(
  () => props.fluxo.dadosBasicos.dataLimiteResposta,
  (novoValor) => {
    if (novoValor) {
      const dateObj = new Date(novoValor);
      if (!isNaN(dateObj.getTime())) {
        localDataLimite.value = dateObj.toISOString().split("T")[0];
      }
    }
  },
);

// Função para tratar a atualização do checkbox
// teste
function onChangeLigado(newValue) {
  ligadoLocal.value = newValue;
  emit("update:ligado", newValue);
}
</script>

<template>
  <TabAccordion
    field="dados"
    header-title="Detalhes"
    :header-subtitle="fluxo.dadosBasicos.status"
    header-icon-name="data"
    v-bind="{ ...$attrs }"
  >
    <GapperBase>
      <RowForm>
        <ColBase>
          <ControlInput
            v-model="fluxo.dadosBasicos.nome"
            field="nome"
            :disabled="disabled"
            rules="required|min:3|max:100"
            label="Nome"
          />
        </ColBase>
        <ColBase sm>
          <FlowFormBasicTabControlGroup
            v-model="fluxo.dadosBasicos.idGrupo"
            :disabled="disabled"
          />
        </ColBase>
      </RowForm>

      <flowFormBasicTabControlInterval
        v-model="fluxo.dadosBasicos.prazoAlertaRespostaPendente"
        :disabled="disabled"
      />

      <ControlInput
        :value="flowRequester.nome"
        field="requisitante"
        disabled
        label="Criado por:"
      />
      <ControlTextarea
        v-model="fluxo.dadosBasicos.descricao"
        field="descricao"
        :disabled="disabled"
        rules="required|min:3|max:1000"
        label="Descrição"
      />

      <!-- Checkbox que controla a exibição do input de data -->
      <FlowFormBasicTabControlData
        v-model="ligadoLocal"
        :disabled="disabled"
        :group-id="fluxo.dadosBasicos.idGrupo"
        :flow-status="fluxo.dadosBasicos.status"
        @input="onChangeLigado"
      />

      <!-- Input de data: exibido se ligadoLocal for true -->
      <div
        v-if="ligadoLocal"
        style="margin-top: 1rem;"
      >
        <label for="data">Data limite:</label>
        <input
          id="data"
          v-model="localDataLimite"
          type="date"
          :min="minDate"
        >
      </div>

      <FlowFormBasicTabControlIcp
        v-model="fluxo.dadosBasicos.isIcp"
        :group-id="fluxo.dadosBasicos.idGrupo"
        :disabled="disabled"
        :flow-status="fluxo.dadosBasicos.status"
      />
      <FlowFormBasicTabControlSequencial
        :is-sequencial.sync="fluxo.dadosBasicos.isSequencial"
        :is-interrompe-se-negativo.sync="fluxo.dadosBasicos.isInterrompeSeNegativo"
        :interessados.sync="fluxo.interessados"
        :disabled="disabled"
      />
    </GapperBase>
  </TabAccordion>
</template>
