var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "display-value",
      class: {
        disabled: _vm.disabled,
        valid: _vm.state === true,
        invalid: _vm.state === false,
      },
      attrs: { type: "button" },
    },
    [
      _c("div", [_vm._v("\n    " + _vm._s(_setup.text) + "\n  ")]),
      _vm._v(" "),
      _vm.isBusy
        ? _c(_setup.SpinnerBase, { attrs: { size: "var(--w-size-35)" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }