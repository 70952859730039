var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ListBase, {
    attrs: {
      id: _vm.id,
      options: _vm.options,
      disabled: _vm.disabled,
      status: "selected",
    },
    on: {
      toggle: function ($event) {
        return _vm.$emit("toggle", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }