<template>
  <div class="card-base mt-3 mx-auto">
    <HeaderText :text="headerText" />
    <div
      class="card-base-content"
      :class="{ shadow: !hideShadow && !transparent, transparent, tight }"
    >
      <LinearBase
        class="card-base-linear"
        :is-busy="showLinear && !showOverlay"
      />
      <OverlayBase :is-busy="showOverlay">
        <slot />
      </OverlayBase>
    </div>
  </div>
</template>

<script>
import { LinearBase, OverlayBase } from "@/lib/async";

import { HeaderText } from "../text";

export default {
  components: { HeaderText, LinearBase, OverlayBase },
  name: "CardBase",
  props: {
    headerText: {
      default: null,
      type: String,
    },
    hideShadow: {
      default: false,
      type: Boolean,
    },
    showLinear: {
      default: false,
      type: Boolean,
    },
    showOverlay: {
      default: false,
      type: Boolean,
    },
    tight: {
      default: false,
      type: Boolean,
    },
    transparent: {
      default: false,
      type: Boolean,
    },
    wrapperStyle: {
      default: null,
      type: Object,
    },
  },
};
</script>

<style scoped>
.card-base-content {
  position: relative;
  overflow: hidden;
  background-color: var(--w-color-neutral-10);
  padding-block: var(--w-size-35);
  padding-inline: var(--w-size-35);
  margin-top: var(--w-size-35);
  border-radius: .25rem;

  &.transparent {
    background-color: transparent;
  }

  &.tight {
    padding-block: 0;
    padding-inline: 0;
    margin-top: 0;
  }
}

.card-base-linear {
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>
