import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

export function handleWindowOnerror(logger) {
  return (message, sourceFile, lineno, colno, error) => {
    const source = `window.onerror: ${message} at ${sourceFile}":"${lineno}":"${colno}`;
    const meta = { error, source };
    handle({ logger, message, meta });
    return true;
  };
}

export function handleWindowRejection(logger) {
  return (event) => {
    const source = "window.onunhandledrejection";
    const message = event.reason?.message || source;
    const meta = { error: event.reason, source };
    handle({ logger, message, meta });
  };
}

export function handleVue2Error(logger) {
  // não é possível incluir "vm" em meta pois não é um recursso passível de serialização via JSON.stringify
  return (error, vm, info) => {
    const source = "Vue.config.errorHandler";
    const message = `${error.message} at ${info}`;
    const meta = { error, info, source };

    handle({ logger, message, meta });
  };
}

function handle({ logger, message, meta }) {
  const severity = SYSLOG_SEVERITIES.CRITICAL;
  const appError = new AppError({ message, meta, severity });
  logger.log(appError);
}
