import { asIndex, isEqual } from "@/lib/aggregates";
import { idle } from "@/lib/async";
import Vue from "vue";

export function createItemsFeatures(config) {
  const { cacheEndpoints } = config;
  return {
    actions: {
      async reconciliateItemsFromRemote({ commit, getters }, remoteItems) {
        for (const serverItem of remoteItems) {
          const clientItem = getters.getItemById(serverItem.id);
          const isAbsentOnClient = !clientItem;
          const isDifferentFromClient = !isEqual(serverItem, clientItem);
          if (isAbsentOnClient || isDifferentFromClient) {
            await idle(); // mudanças no state vão impactar a ui, então aguardamos liberar processamento. aplicar idle no loop levou a tempos excessivos de polling.
            commit("setItemInState", serverItem);
          }
        }

        const clientItems = getters.getAllItems;
        const remoteIndex = asIndex(remoteItems);
        for (const { id: clientId } of clientItems) {
          const isAbsentOnServer = !remoteIndex[clientId];
          if (isAbsentOnServer) {
            await idle(); // mudanças no state vão impactar a ui, então aguardamos liberar processamento. aplicar idle no loop levou a tempos excessivos de polling.
            commit("removeItemInState", clientId);
          }
        }

        await idle();
        await resetCache({ cacheEndpoints, items: remoteItems });
      },

      async resetItemsFrom({ commit }, { items, origin }) {
        commit("resetItemsInState", items);
        if (origin === "cache") return;
        await resetCache({ cacheEndpoints, items });
      },

      async upsertItemFromRemote({ commit }, item) {
        commit("setItemInState", item);
        await upsertCache({ cacheEndpoints, item });
      },
    },
    getters: {
      filterItemsByFieldValue: (state, getters) => (field, value) =>
        getters.getAllItems.filter(item => item[field] === value),
      filterItemsByFieldWithSomeValue: (state, getters) => (field, values) =>
        getters.getAllItems.filter(item => values.includes(item[field])),
      getAllItems: (state) => {
        if (!state.items || typeof state.items !== "object") return [];
        return Object.values(state.items);
      },
      getItemByFieldValue: (state, getters) => (field, value) =>
        getters.getAllItems.find(item => item[field] === value),
      getItemById: state => id => state.items[id],
    },
    mutations: {
      removeItemInState(state, id) {
        Vue.delete(state.items, id);
      },
      resetItemsInState: (state, arrayOrHash) => {
        state.items = asIndex(arrayOrHash);
      },
      setItemInState(state, item) {
        Vue.set(state.items, item.id, item);
      },
    },
    state: {
      items: {},
    },
  };
}

async function resetCache({ cacheEndpoints, items }) {
  if (!cacheEndpoints?.replace) return;
  await cacheEndpoints.replace.dispatch(items);
}

async function upsertCache({ cacheEndpoints, item }) {
  if (!cacheEndpoints?.upsert) return;
  await cacheEndpoints.upsert.dispatch(item);
}
