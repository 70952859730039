import { createCollectionStoreModule } from "@/lib";

export function createFluxosArchivedStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    cacheEndpoints: {
      read: endpoints.flowsArchivedCacheRead,
      replace: endpoints.flowsArchivedCacheReplace,
      upsert: endpoints.flowsArchivedCacheUpsert,
    },
    pollingSeconds,
    queryEndpoint: endpoints.flowsArchivedList,
  });
}
