<template>
  <ControlSelectSingle
    v-bind="$attrs"
    v-model="selectedOption"
    field="prazoAlertaRespostaPendente"
    :options="intervals"
    value-field="id"
    text-field="nome"
    rules="required"
    label="Intervalo entre avisos"
    :helper-text="'Automaticamente o sistema irá notificar o usuário no momento em que o fluxo é iniciado e no dia anterior a data limite para resposta do fluxo.'"
    v-on="$listeners"
  />
</template>

<script>
import { intervalBetweenWarnings } from "@/data/interval";
import { ControlSelectSingle } from "@/lib";

export default {

  components: {
    ControlSelectSingle,
  },

  computed: {

    intervals() {
      return intervalBetweenWarnings();
    },
  },
  data() {
    return {
      selectedOption: "1",
    };
  },
  name: "FlowFormBasicTabInterval",
};
</script>
