import { decodeManager, encodeManager } from "./code";

export function createManagersEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }) {
  const managersUrl = "/v1/contas/perfil/GESTOR";
  return {
    managerAdd: backendApi.createEndpoint({
      decode: decodeManager,
      encode: encodeManager,
      method: "post",
      url: managersUrl,
    }),

    managerEdit: backendApi.createEndpoint({
      decode: decodeManager,
      encode: encodeManager,
      method: "put",
      url: ({ id }) => `${managersUrl}/${id}`,
    }),

    managersCacheRead: cacheApi.createEndpoint({
      method: "read",
      store: cacheApiSchema.stores.managers,
    }),

    managersCacheReplace: cacheApi.createEndpoint({
      method: "replace",
      store: cacheApiSchema.stores.managers,
    }),

    managersCacheUpsert: cacheApi.createEndpoint({
      method: "upsert",
      store: cacheApiSchema.stores.managers,
    }),

    managersList: backendApi.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeManager), pagination }),
      method: "get",
      url: page => `${managersUrl}?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),
  };
}
