import { Actions } from "./actions";
import { Plugins } from "./plugins";
import { State } from "./state";

export class Module {
  actions;
  plugins;
  state;
  store;

  constructor(options = {}) {
    this.store = options.store;

    this.plugins = new Plugins({
      container: this,
      options: options.plugins,
    });

    this.state = new State({
      container: this,
      options: { ...this.plugins.reduceState(), ...options.state },
    });

    this.actions = new Actions({
      container: this,
      options: { ...this.plugins.reduceActions(), ...options.actions },
    });
  }

  patch(options) {
    this.state.patch(options);
  }

  reset() {
    this.state.reset();
  }
}
