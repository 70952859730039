import { inject, provide } from "@/lib/use";

import { NotificationsService } from "./service";

export const key = "$notifications";

export function installNotifications() {
  const instance = new NotificationsService();
  provide({ instance, key });
  return instance;
}

export function injectNotifications() {
  return inject(key);
}
