var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-lookup", attrs: { id: _setup.parentId } },
    [
      _c(
        _setup.DisplayValue,
        _vm._b(
          {
            attrs: {
              selected: _setup.selected,
              disabled: _setup.disabled,
              "is-busy": _vm.isBusy,
              state: _vm.state,
              placeholder: _vm.placeholder,
            },
          },
          "DisplayValue",
          _setup.anchorProperties,
          false
        )
      ),
      _vm._v(" "),
      !_setup.disabled || _setup.isArrayFull(_setup.selected)
        ? _c(
            _setup.DropdownSurface,
            _vm._b(
              {
                attrs: {
                  rows: _vm.rows,
                  "anchor-box": _setup.anchorBox,
                  "is-busy": _vm.isBusy,
                },
                on: { contentEnd: _setup.handleRequest },
                scopedSlots: _vm._u(
                  [
                    !_setup.disabled
                      ? {
                          key: "static",
                          fn: function () {
                            return [
                              _c(_setup.InputFilter, {
                                attrs: {
                                  id: _setup.kebabCase(
                                    _setup.parentId,
                                    "filter"
                                  ),
                                  add: _vm.add,
                                },
                                on: {
                                  input: _setup.handleRequest,
                                  added: function ($event) {
                                    return _setup.handleAndHide(
                                      _setup.handleAdded,
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _setup.filter,
                                  callback: function ($$v) {
                                    _setup.filter = $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                              _vm._v(" "),
                              _setup.isArrayFull(_setup.selected) ||
                              _setup.isArrayFull(_setup.unselected)
                                ? _c("hr", {
                                    staticClass: "input-dropdown__divider",
                                  })
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "scrollable",
                      fn: function () {
                        return [
                          _c(_setup.ListSelected, {
                            attrs: {
                              id: _setup.kebabCase(_setup.parentId, "selected"),
                              disabled: _setup.disabled,
                              options: _setup.selected,
                            },
                            on: { toggle: _setup.handleUnselected },
                          }),
                          _vm._v(" "),
                          _setup.isArrayFull(_setup.selected) &&
                          _setup.isArrayFull(_setup.unselected)
                            ? _c("hr", {
                                staticClass: "input-dropdown__divider",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(_setup.ListUnselected, {
                            attrs: {
                              id: _setup.kebabCase(
                                _setup.parentId,
                                "unselected"
                              ),
                              disabled: _setup.disabled,
                              options: _setup.unselected,
                            },
                            on: {
                              toggle: function ($event) {
                                return _setup.handleAndHide(
                                  _setup.handleSelected,
                                  $event
                                )
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              "DropdownSurface",
              _setup.popoverProperties,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }