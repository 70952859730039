<script setup>
import { createDefaultRequester, groupsWithInactiveLabel, isManagerGroup, isRequesterPessoa, isRequesterSistema } from "@/data";
import { ButtonsSaveBack, ColBase, ControlCheckbox, ControlSelectMulti, ControlSelectSingle, ControlSet, DialogForm, MODES, RowForm, SpacerBase, isEditMode } from "@/lib";
import { AccountDialog } from "@/views/widgets/account-dialog";
import { WebhookFields } from "@/views/widgets/webhook-fields";
import { computed, ref } from "vue";

const props = defineProps({
  codigoApi: {
    default: null,
    type: Function,
  },
  contas: {
    default: null,
    type: Array,
  },
  defaultRequester: {
    default: createDefaultRequester,
    type: Object,
  },
  externalErrors: {
    default: null,
    type: Object,
  },
  groups: {
    default: () => ([]),
    type: Array,
  },
  managerId: {
    default: null,
    type: String,
  },
  mode: {
    default: MODES.VIEW,
    type: String,
  },
});

const accountDialog = ref(null);

const groupsAsOptions = computed(() => {
  // grupos que já fazem parte do requisitante devem aparecer na listagem mesmo que sem vinculo com o gestor atual
  const requesterGroups = props.defaultRequester.grupos || [];
  const isRequesterGroup = g => requesterGroups.find(id => g.id === id);

  return groupsWithInactiveLabel(props.groups)
    .filter(g => isManagerGroup(g, props.managerId) || isRequesterGroup(g))
    .map(g => ({
      ...g,
      // se o grupo não for do gestor deve aparecer desabilitado mesmo que ainda seja um grupo ativo
      disabled: !isManagerGroup(g, props.managerId),
    }));
});

function isPessoa(payload) {
  return isRequesterPessoa(payload);
}

function isSistema(payload) {
  return isRequesterSistema(payload);
}
</script>

<template>
  <DialogForm
    :default-payload="defaultRequester"
    :external-errors="externalErrors"
    :external-errors-field-map="{
      codigo: 'codigoRequisitante',
      'apikey.valor': 'apikeyValue',
      'apikey.chave': 'apikeyName',
      'oauth2.tokenUrl': 'oauth2Url',
      'oauth2.clientId': 'oauth2Client',
      'oauth2.clientSecret': 'oauth2Secret'
    }"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload, patch }">
      <RowForm>
        <ColBase>
          <ControlSelectSingle
            v-model="payload.tipoConta"
            field="tipoConta"
            :disabled="isEditMode(mode)"
            :options="['PESSOA', 'SISTEMA']"
            rules="required"
            label="Tipo de Requisitante"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase>
          <ControlSelectMulti
            v-model="payload.grupos"
            field="grupos"
            :options="groupsAsOptions"
            value-field="id"
            text-field="nome"
            :rows="3"
            label="Grupos"
          />
        </ColBase>
      </RowForm>
      <ControlSet legend="dados da conta">
        <AccountDialog
          ref="accountDialog"
          :value="payload"
          :celular-label="isPessoa(payload) ? 'Celular' : 'Celular de contato'"
          :codigo-api="codigoApi"
          :contas="contas"
          :email-label="isPessoa(payload) ? 'E-mail' : 'E-mail do sistema'"
          :mode="mode"
          field="requisitante"
          @input="patch"
        />
      </ControlSet>
      <template v-if="isSistema(payload)">
        <SpacerBase vertical="10" />
        <WebhookFields v-model="payload.webhook" />
      </template>
      <template v-if="isEditMode(mode)">
        <SpacerBase vertical="10" />
        <RowForm>
          <ColBase>
            <ControlCheckbox
              v-model="payload.ativo"
              field="ativo"
              placeholder="Ativar Requisitante"
              switch
            />
          </ColBase>
        </RowForm>
      </template>
    </template>
    <template #actions>
      <slot
        name="actions"
        :clear-account-data="() => accountDialog.reset()"
      />
      <ButtonsSaveBack @back="$emit('back')" />
    </template>
  </DialogForm>
</template>
