var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-lookup" },
    [
      !_vm.hide
        ? _c(
            "BFormInput",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.mask,
                    expression: "mask",
                  },
                ],
                class: _vm.inputClass,
                attrs: {
                  placeholder: _vm.conditionalPlaceholder,
                  readonly: _vm.disabled,
                  autocomplete: _vm.autocompleteNormalized,
                  type: _vm.lookupCompatibleType,
                },
                on: {
                  blur: _vm.handleBlur,
                  focus: _vm.handleFocus,
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.handleLookupSelectByKeyboard.apply(
                        null,
                        arguments
                      )
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      return _vm.decLookupChosenIndex.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      return _vm.incLookupChosenIndex.apply(null, arguments)
                    },
                  ],
                },
                model: {
                  value: _vm.display,
                  callback: function ($$v) {
                    _vm.display = $$v
                  },
                  expression: "display",
                },
              },
              "BFormInput",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLookupOpen
        ? _c(
            "div",
            {
              staticClass: "wrapper",
              attrs: { id: _vm.camelCase("lookup", _vm.$attrs.id) },
            },
            [
              _c(
                "ul",
                { ref: "menu", staticClass: "lookup-menu" },
                _vm._l(_vm.lookupList, function (option, index) {
                  return _c(
                    "li",
                    {
                      key: option?.value ?? index,
                      staticClass: "lookup-item",
                      class: { active: index === _vm.chosenIndex },
                      attrs: { id: option?.value ?? index },
                      on: {
                        mousedown: _vm.handleLookupSelectByMouse,
                        mouseover: function ($event) {
                          return _vm.setLookupChosenIndex(index)
                        },
                      },
                    },
                    [_vm._v("\n        " + _vm._s(option.text) + "\n      ")]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }