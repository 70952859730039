import { decodeStakeholder, encodeStakeholder } from "./code";

export function createStakeholdersEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }) {
  const stakeholdersUrl = "/v1/contas/perfil/INTERESSADO";

  return {
    stakeholderAdd: backendApi.createEndpoint({
      decode: decodeStakeholder,
      encode: encodeStakeholder,
      method: "post",
      url: stakeholdersUrl,
    }),

    stakeholderEdit: backendApi.createEndpoint({
      decode: decodeStakeholder,
      encode: encodeStakeholder,
      method: "put",
      url: ({ id }) => `${stakeholdersUrl}/${id}`,
    }),

    stakeholdersCacheRead: cacheApi.createEndpoint({
      method: "read",
      store: cacheApiSchema.stores.stakeholders,
    }),

    stakeholdersCacheReplace: cacheApi.createEndpoint({
      method: "replace",
      store: cacheApiSchema.stores.stakeholders,
    }),

    stakeholdersCacheUpsert: cacheApi.createEndpoint({
      method: "upsert",
      store: cacheApiSchema.stores.stakeholders,
    }),

    stakeholdersList: backendApi.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeStakeholder), pagination }),
      method: "get",
      url: page => `${stakeholdersUrl}?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),
  };
}
