var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("router-view"),
      _vm._v(" "),
      _c(_setup.ManagerSession),
      _vm._v(" "),
      _c(_setup.NotificationsGlobal),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }