import { decodeGroup, encodeGroup } from "./code";

export function createGroupsEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }) {
  const groupsUrl = "/v1/grupos";
  return {
    groupAdd: backendApi.createEndpoint({
      decode: decodeGroup,
      encode: encodeGroup,
      method: "post",
      url: groupsUrl,
    }),

    groupEdit: backendApi.createEndpoint({
      decode: decodeGroup,
      encode: encodeGroup,
      method: "put",
      url: ({ id }) => `${groupsUrl}/${id}`,
    }),

    groupsCacheRead: cacheApi.createEndpoint({
      method: "read",
      store: cacheApiSchema.stores.groups,
    }),

    groupsCacheReplace: cacheApi.createEndpoint({
      method: "replace",
      store: cacheApiSchema.stores.groups,
    }),

    groupsCacheUpsert: cacheApi.createEndpoint({
      method: "upsert",
      store: cacheApiSchema.stores.groups,
    }),

    groupsList: backendApi.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeGroup), pagination }),
      method: "get",
      url: page => `${groupsUrl}?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),
  };
}
