import Vue from "vue";

import App from "./app";

export function initApp({ endpoints, environment, router, store }) {
  const app = new Vue({
    components: { App },
    render: h => h(App),
    router,
    store,
  });

  // permite acesso dentro dos componentes vue
  Vue.prototype.$endpoints = endpoints;
  Vue.prototype.$environment = environment;

  return app;
}
