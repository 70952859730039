var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    {
      staticClass: "list-base",
      class: { disabled: _vm.disabled },
      attrs: { id: _setup.kebabCase(_vm.id, "list") },
    },
    _vm._l(_vm.options, function (option, index) {
      return _c(
        "li",
        {
          key: option.value,
          staticClass: "list-base-item",
          attrs: { id: _setup.kebabCase(_vm.id, "item", option.value) },
        },
        [
          _c("input", {
            staticClass: "list-base-item-check",
            attrs: {
              id: option.value,
              type: "checkbox",
              disabled: _vm.disabled,
            },
            domProps: { checked: _vm.status === "selected" },
            on: {
              input: function ($event) {
                return _setup.handleToggle({ option, index })
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "list-base-item-text",
              attrs: { for: option.value },
            },
            [
              _c("span", [_vm._v(_vm._s(option.text))]),
              _vm._v(" "),
              option.added
                ? _c(_setup.AppIcon, {
                    attrs: { name: "new", variant: "info" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }