<script setup>
import { createDefaultWebhook } from "@/data";
import { AlertText, ColBase, ControlCheckbox, ControlInput, ControlSet, RowForm, camelCase, isEqual } from "@/lib";
import { computed, ref, watch } from "vue";

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  value: {
    default: createDefaultWebhook,
    type: Object,
  },
});
const emit = defineEmits(["input"]);

const localValue = ref();

watch(
  () => props.value,
  (newValue) => {
    const normalizedValue = newValue || createDefaultWebhook();
    if (isEqual(normalizedValue, localValue.value)) return;
    localValue.value = normalizedValue;
  },
  { deep: true, immediate: true },
);

watch(
  () => localValue.value,
  (newValue) => {
    if (isEqual(newValue, props.value)) return;
    emit("input", newValue);
  },
  { deep: true },
);

const hasWebhook = computed(() =>
  localValue.value.url
  || localValue.value.apikeyHas
  || localValue.value.oauth2Has,
);
</script>
<template>
  <ControlSet legend="Webhook">
    <AlertText
      v-if="!disabled"
      text="A seguir você pode cadastrar os dados de um webhook que será acionado após a conclusão dos fluxos de assinaturas criados por este requisitante."
    />
    <RowForm>
      <ColBase sm="8">
        <ControlInput
          v-model="localValue.url"
          field="url"
          :rules="hasWebhook ? 'required|url' : null"
          label="URL"
          helper-text="URL do webhook a ser notificado após conclusão de fluxos"
          :disabled="disabled"
        />
      </ColBase>
      <ColBase sm="4">
        <ControlInput
          v-model="localValue.method"
          field="method"
          :rules="hasWebhook ? 'required' : null"
          label="Método"
          helper-text="Método HTTP a ser utilizado na notificação"
          :disabled="disabled"
        />
      </ColBase>
    </RowForm>
    <ControlSet
      v-if="!disabled || localValue.apikeyHas"
      legend="ApiKey"
      divider
    >
      <RowForm v-if="!disabled">
        <ColBase>
          <ControlCheckbox
            v-model="localValue.apikeyHas"
            placeholder="Autenticar usando Apikey"
            :field="camelCase('apikey', 'has')"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase sm>
          <ControlInput
            v-model="localValue.apikeyName"
            :field="camelCase('apikey', 'name')"
            :rules="localValue.apikeyHas ? 'required' : null"
            label="Nome"
            helper-text="Nome da chave da API"
            :disabled="!localValue.apikeyHas || disabled"
          />
        </ColBase>
        <ColBase sm>
          <ControlInput
            v-model="localValue.apikeyValue"
            :field="camelCase('apikey', 'value')"
            :disabled="!localValue.apikeyHas || disabled"
            :rules="localValue.apikeyHas ? 'required' : null"
            label="Valor"
            helper-text="Valor da chave da API"
          />
        </ColBase>
      </RowForm>
    </ControlSet>
    <ControlSet
      v-if="!disabled || localValue.oauth2Has"
      legend="Oauth2"
      divider
    >
      <RowForm v-if="!disabled">
        <ColBase>
          <ControlCheckbox
            v-model="localValue.oauth2Has"
            placeholder="Autenticar usando OAuth2"
            :field="camelCase('oauth2', 'has')"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase sm>
          <ControlInput
            v-model="localValue.oauth2Url"
            :field="camelCase('oauth2', 'url')"
            :disabled="!localValue.oauth2Has || disabled"
            :rules="localValue.oauth2Has ? 'required|url' : null"
            label="Url"
            helper-text="URL para obtenção do token OAuth2"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase sm>
          <ControlInput
            v-model="localValue.oauth2Client"
            :field="camelCase('oauth2', 'client')"
            :rules="localValue.oauth2Has ? 'required' : null"
            :disabled="!localValue.oauth2Has || disabled"
            label="Cliente"
            helper-text="Identificação do cliente OAuth2"
          />
        </ColBase>
        <ColBase sm>
          <ControlInput
            v-model="localValue.oauth2Secret"
            :field="camelCase('oauth2', 'secret')"
            :rules="localValue.oauth2Has ? 'required' : null"
            :disabled="!localValue.oauth2Has || disabled"
            label="Segredo"
            helper-text="Segredo do cliente OAuth2 para obtenção do token"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase sm>
          <ControlInput
            v-model="localValue.oauth2Type"
            :field="camelCase('oauth2', 'type')"
            :disabled="!localValue.oauth2Has || disabled"
            :rules="localValue.oauth2Has ? 'required' : null"
            label="Tipo"
            helper-text="Tipo de autenticação OAuth2"
          />
        </ColBase>
        <ColBase sm>
          <ControlInput
            v-model="localValue.oauth2Scope"
            :field="camelCase('oauth2', 'scope')"
            :disabled="!localValue.oauth2Has || disabled"
            label="Escopo"
            helper-text="Escopo da autenticação OAuth2"
          />
        </ColBase>
      </RowForm>
    </ControlSet>
  </ControlSet>
</template>
