var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c(_setup.NavBar, {
        attrs: { "is-open": _setup.showSideBar },
        on: {
          "update:isOpen": function ($event) {
            _setup.showSideBar = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "logo",
              fn: function () {
                return [_vm._t("navStart")]
              },
              proxy: true,
            },
            {
              key: "links",
              fn: function () {
                return [_vm._t("navEnd")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(_setup.SideBar, {
        attrs: {
          id: "navSidebar",
          links: _vm.sideLinks,
          "is-open": _setup.showSideBar,
        },
        on: {
          "update:isOpen": function ($event) {
            _setup.showSideBar = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-2 p-sm-5" },
        [
          _c(_setup.NavCrumb, {
            staticClass: "d-none d-sm-flex",
            attrs: { "data-test": "nav-crumbs", path: _vm.crumbs },
          }),
          _vm._v(" "),
          _c(
            _setup.CardBase,
            {
              attrs: {
                "header-text": _vm.headerText,
                "show-overlay":
                  _setup.isBusyWithExecute ||
                  _setup.isBusyWithFirstQuerying ||
                  _vm.showOverlay,
                "show-linear":
                  _setup.isBusyWithQuerying ||
                  _setup.isBusyWithRelatedQuerying ||
                  _vm.showLinear,
                transparent: _vm.noCard,
                tight: _vm.noCard,
              },
            },
            [
              _c(
                "main",
                [
                  _setup.isBusyWithFirstQuerying && _vm.afterFirstQuery
                    ? [_c(_setup.SkeletonLoader)]
                    : [
                        _vm._t("content", null, {
                          execute: _setup.execute,
                          lastError: _setup.lastError,
                          isBusyWithFirstQuerying:
                            _setup.isBusyWithFirstQuerying,
                          isBusyWithQuerying: _setup.isBusyWithQuerying,
                          isBusyWithExecute: _setup.isBusyWithExecute,
                          isBusyWithRelatedQuerying:
                            _setup.isBusyWithRelatedQuerying,
                        }),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }