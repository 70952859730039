<script setup>
import { ButtonTertiary, ButtonsSaveBack, ColBase, ControlSelectSingle, DialogForm, MODES, ModalBase, RowForm } from "@/lib";
import { AccountDialog } from "@/views/widgets/account-dialog";
import { ref } from "vue";

defineProps({
  codigoApi: {
    required: true,
    type: Function,
  },
  contas: {
    default: null,
    type: Array,
  },
});
defineExpose({ show });

const defaultInteressado = ref({
  papel: "ASSINANTE",
  resposta: { data: null, status: "PENDENTE" },
  tipoCodigo: "CPF",
});

const accountDialog = ref(null);
function handleReset() {
  accountDialog.value.reset();
}

const modal = ref(null);
function show() {
  return modal.value.show();
}
</script>

<template>
  <ModalBase
    ref="modal"
    size="lg"
  >
    <template #body="{ save, cancel }">
      <DialogForm
        :default-payload="defaultInteressado"
        show-action-divider
        @save="save($event)"
      >
        <template #body="{ payload, patch }">
          <RowForm>
            <ColBase>
              <ControlSelectSingle
                v-model="payload.papel"
                field="papel"
                :options="['ASSINANTE', 'REVISOR']"
                rules="required"
                label="Papel"
              />
            </ColBase>
          </RowForm>
          <AccountDialog
            ref="accountDialog"
            :value="payload"
            :codigo-api="codigoApi"
            :contas="contas"
            field="interessado"
            :mode="MODES.ADD"
            @input="patch"
          />
        </template>
        <template #actions>
          <ButtonTertiary
            field="reset"
            label="Limpar"
            @click="handleReset"
          />
          <ButtonsSaveBack @back="cancel()" />
        </template>
      </DialogForm>
    </template>
  </ModalBase>
</template>
