import { asArray } from "@/lib";

export function createDefaultGroup(initialGestor) {
  return {
    ativo: true,
    gestores: asArray(initialGestor),
    standardApprovalBlocked: false,
    standardApprovalIsIcp: false,
  };
}
