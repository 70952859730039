import { asArray, sortByField } from "@/lib/aggregates";
import { uuid } from "@/lib/crypto";
import { stringIncludes } from "@/lib/strings";
import { computed, ref } from "vue";

export function useOptions({
  disabled: disabledRef,
  filter: filterRef,
  model: modelRef,
  multi: multiRef,
  options: optionsRef,
  textField: textFieldRef,
  valueField: valueFieldRef,
}) {
  const addedItems = ref([]);

  const availableNormlizedAndSortedtems = computed(() => {
    const textField = textFieldRef.value;
    const valueField = valueFieldRef.value;
    const fieldAdjustedOptions = optionsRef.value.map(option => ({ text: option[textField], value: option[valueField] }));

    const optionsPlusAdded = fieldAdjustedOptions.concat(addedItems.value);

    const sortedOptions = sortByField(optionsPlusAdded, { field: "text" });
    return sortedOptions;
  });

  const selected = computed(() => availableNormlizedAndSortedtems.value.filter(option => modelIncludes(option)));
  const unselected = computed(() => {
    if (disabledRef.value) return [];
    return availableNormlizedAndSortedtems.value.filter(option => filterIncludes(option) && !modelIncludes(option));
  });

  function handleSelected({ value }) {
    if (disabledRef.value) return;

    let newModel;
    if (multiRef.value) {
      newModel = asArray(modelRef.value);
      newModel.push(value);
    }
    else {
      newModel = value;
    }

    modelRef.value = newModel;
  }

  function handleAdded(text) {
    const newItem = { added: true, text, value: `temp-${uuid()}` };
    addedItems.value.push(newItem);
    handleSelected(newItem);
  }

  function handleUnselected(option) {
    if (disabledRef.value) return;

    let newModel;
    if (multiRef.value) {
      newModel = asArray(modelRef.value);
      const index = newModel.indexOf(option.value);
      newModel.splice(index, 1);
    }
    else {
      newModel = null;
    }

    modelRef.value = newModel;
  }

  function modelIncludes(option) {
    return asArray(modelRef.value).includes(option.value);
  }

  function filterIncludes(option) {
    return !filterRef.value || stringIncludes(option.text, filterRef.value);
  }

  return { handleAdded, handleSelected, handleUnselected, selected, unselected };
}
