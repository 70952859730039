var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.legend
        ? _c(_setup.LabelBase, { attrs: { text: _vm.legend } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "control-set", class: { divider: _vm.divider } },
        [_c(_setup.GapperBase, [_vm._t("default")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }