var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.standardApprovalBlocked
        ? _c(_setup.AlertText, {
            attrs: {
              text: "O gestor do grupo não permite alteração das configurações de assinatura",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(_setup.DataLimite, {
        attrs: { disabled: _setup.normalDisabled, value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }