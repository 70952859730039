<script setup>
import { asArray } from "@/lib/aggregates";
import { ButtonClose } from "@/lib/components/buttons";
import { AppIcon } from "@/lib/components/icons";
import { ColBase, RowBase } from "@/lib/components/layout";
import { useTimer } from "@/lib/time";
import { computed, ref } from "vue";

import ModalBase from "./modal-base";

const props = defineProps({
  countdown: {
    default: null,
    type: Number,
  },
  icon: {
    required: true,
    type: String,
  },
  message: {
    required: true,
    type: String,
  },
  messageDetail: {
    default: null,
    type: [Array, String],
  },
  title: {
    required: true,
    type: String,
  },
  variant: {
    required: true,
    type: String,
  },
});
defineExpose({ show });

const timer = props.countdown && useTimer(props.countdown);

const messageNormalized = computed(() => {
  if (!timer) return props.message;
  return props.message.replaceAll("{countdown}", timer.time.value);
});

const messageDetailNormalized = computed(() => asArray(props.messageDetail));
const hasDetail = computed(() => messageDetailNormalized.value.length >= 1);

const modal = ref("modal");
function show() {
  return modal.value.show();
}
</script>

<template>
  <ModalBase
    ref="modal"
    show-action-divider
    :timer="timer"
  >
    <template #body="{ cancel }">
      <RowBase>
        <ColBase class="d-none d-sm-block">
          <div class="d-flex justify-content-center">
            <AppIcon
              :name="icon"
              :variant="variant"
              font-scale="5"
            />
          </div>
        </ColBase>
        <ColBase
          cols="12"
          sm="9"
        >
          <p class="message-title">
            <AppIcon
              :name="icon"
              :variant="variant"
              class="d-inline-block d-sm-none mr-2"
            />
            {{ title }}
          </p>
          <p>{{ messageNormalized }}</p>
          <ul
            v-if="hasDetail"
            class="detail"
          >
            <li
              v-for="part in messageDetailNormalized"
              :key="part"
            >
              {{ part }}
            </li>
          </ul>
        </ColBase>
      </RowBase>

      <ButtonClose @click="cancel()" />
    </template>
    <template #actions="{ save, cancel }">
      <slot
        :save="save"
        :cancel="cancel"
      />
    </template>
  </ModalBase>
</template>

<style scoped>
.detail {
  font-weight: var(--w-font-weight-10);
  padding: 0;
  list-style-position: inside;
}

.message-title {
  font-size: var(--w-font-size-45);
  font-weight: var(--w-font-weight-30);
}
</style>
