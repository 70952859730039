import { validateCnpj, validateCpf } from "@/lib/cpf-cnpj";
import { isMobile } from "@/lib/phones";
import { getContentSizeFromRichText } from "@/lib/rich-text-suite";
import { extend, localize } from "vee-validate";
import ptBR from "vee-validate/dist/locale/pt_BR.json";
import { digits, email, max, min, required } from "vee-validate/dist/rules";

export function installValidationRules() {
  // localização das mensagens de erro padrão para português
  // https://logaretm.github.io/vee-validate/guide/localization.html#using-the-default-i18n
  localize("ptBR", ptBR);

  /*
    regras de validação padrão da biblioteca precisam ser importadas e instaladas
    explicitamente. a lista completa de regras disponíveis está aqui:
    https://logaretm.github.io/vee-validate/guide/rules.html#rules
  */
  extend("email", email);
  extend("required", required);
  extend("min", min);
  extend("max", max);
  extend("digits", digits);

  extend("url", {
    message: "O campo {_field_} deve conter uma URL válida",
    validate(value) {
      if (value) {
        return /^(http:\/\/|https:\/\/)(localhost|[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6})(:[0-9]{1,5})?(\/.*)?$/.test(value);
      }

      return false;
    },
  });

  extend("rich-text-min", {
    message: "O campo {_field_} deve ter ao menos {size} itens",
    params: ["size"],
    validate(delta, args) {
      return getContentSizeFromRichText(delta) >= args.size;
    },
  });

  extend("rich-text-max", {
    message: "O campo {_field_} não deve ter mais que {size} itens",
    params: ["size"],
    validate(delta, args) {
      return getContentSizeFromRichText(delta) <= args.size;
    },
  });

  extend("celular", {
    message: "Valor de telefone celular inválido",
    validate(value) {
      return isMobile(value);
    },
  });

  extend("cpf", {
    message: "Valor de CPF inválido",
    validate: value => validateCpf(value),
  });

  extend("cnpj", {
    message: "Valor de CNPJ inválido",
    validate: value => validateCnpj(value),
  });
}
