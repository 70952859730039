import { createCollectionStoreModule } from "@/lib";

export function createGruposStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addGroup({ dispatch }, userGroup) {
        const responseGroup = await endpoints.groupAdd.dispatch(userGroup);
        await dispatch("upsertItemFromRemote", responseGroup);
      },
      async editGroup({ dispatch }, userGroup) {
        const responseGroup = await endpoints.groupEdit.dispatch(userGroup);
        await dispatch("upsertItemFromRemote", responseGroup);
      },
    },
    cacheEndpoints: {
      read: endpoints.groupsCacheRead,
      replace: endpoints.groupsCacheReplace,
      upsert: endpoints.groupsCacheUpsert,
    },
    getters: {
      membrosOfGrupo: (state, getters, rootState, rootGetters) => (grupoId) => {
        const grupo = getters.getItemById(grupoId);
        if (!grupo) return [];

        const { gestores: gestoresIds, requisitantes: requisitantesIds } = grupo;
        const requisitantes = rootGetters["requisitantes/filterItemsByFieldWithSomeValue"]("id", requisitantesIds);
        const gestores = rootGetters["gestores/filterItemsByFieldWithSomeValue"]("id", gestoresIds);

        const membros = requisitantes.concat(gestores);
        return membros;
      },
    },
    pollingSeconds,
    queryEndpoint: endpoints.groupsList,
  });
}
