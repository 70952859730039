import { createCollectionStoreModule } from "@/lib";

export function createFluxosAdministrativoStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addFlow({ dispatch }, userFlow) {
        const responseFlow = await endpoints.flowAdd.dispatch(userFlow);
        await dispatch("upsertItemFromRemote", responseFlow);
        // a ui precisa desse retorno para avançar imediatamente para a tela de edição do fluxo que foi criado
        return responseFlow;
      },

      async archiveFlow({ dispatch }, userFlow) {
        const responseFlow = await endpoints.flowArchive.dispatch(userFlow.id);
        await dispatch("upsertItemFromRemote", responseFlow);
      },

      async cancelFlow({ dispatch }, userFlow) {
        const responseFlow = await endpoints.flowCancel.dispatch(userFlow.id);
        await dispatch("upsertItemFromRemote", responseFlow);
      },

      async deleteDocument({ commit }, { idDocumento, idFluxo }) {
        try {
          await endpoints.documentDelete.dispatch(idDocumento);
          commit("excludeDocumentFromFlow", { idDocumento, idFluxo });
          return { status: "success" };
        }
        catch (error) {
          return { status: "fail", value: error };
        }
      },

      async editFow({ dispatch }, userFlow) {
        const responseFlow = await endpoints.flowEdit.dispatch(userFlow);
        await dispatch("upsertItemFromRemote", responseFlow);
      },

      async finishFlow({ dispatch }, userFlow) {
        const responseFlow = await endpoints.flowFinish.dispatch(userFlow.id);
        await dispatch("upsertItemFromRemote", responseFlow);
      },

      async sendLink(_, { idFluxo, idRequisitante, interessado }) {
        await endpoints.flowLinkSend.dispatch({ idFluxo, idRequisitante, interessado });
      },

      async startFlow({ dispatch }, userFlow) {
        // assegura o salvamento do fluxo antes de inicia-lo
        await dispatch("editFow", userFlow);
        const responseFlow = await endpoints.flowStart.dispatch(userFlow.id);
        await dispatch("upsertItemFromRemote", responseFlow);
      },

      async uploadDocument({ commit }, { file, idFluxo }) {
        try {
          const payload = { file, idFluxo };
          const responseDocument = await endpoints.documentUpload.dispatch(payload);
          commit("includeDocumentInFlow", responseDocument);
          return { status: "success", value: responseDocument };
        }
        catch (error) {
          return { status: "fail", value: error };
        }
      },
    },
    mutations: {
      excludeDocumentFromFlow: (state, { idDocumento, idFluxo }) => {
        const documentos = state.items[idFluxo].documentos;
        const i = documentos.findIndex(doc => doc.id === idDocumento);
        documentos.splice(i, 1);
      },
      includeDocumentInFlow: (state, documento) => {
        const documentos = state.items[documento.idFluxo].documentos;
        documentos.push(documento);
      },
    },
    pollingSeconds,
    queryEndpoint: endpoints.flowsManagementList,
  });
}
