import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

import { ItemsPlugin } from "../items";
import { queryNext } from "./query-next";
import { createQueryPage } from "./query-page";
import { queryUntilConditions } from "./query-until-conditions";

export class CollectionPlugin extends ItemsPlugin {
  constructor({ actions = {}, pageDispatch, state = {} } = {}) {
    if (typeof pageDispatch !== "function") {
      throw new AppError({
        message: "collection plugin requires a pageDispatch function to be passed",
        severity: SYSLOG_SEVERITIES.CRITICAL,
      });
    }
    super({
      actions: {
        queryNext,
        queryPage: createQueryPage(pageDispatch),
        queryUntilConditions,
        ...actions,
      },
      state: {
        isQuerying: ({ queryCount }) => queryCount.value > 0,
        isRemoteExhausted: false,
        queriedPages: new Set(),
        queryCount: 0,
        totalRemote: null,
        ...state,
      },
    });
  }
}
