import { Api } from "../base";
import { IndexedDbEndpoint } from "./endpoint";
import { openIndexedDB } from "./open";

export class IndexedDbApi extends Api {
  db;
  dbNameOrGetter;
  stores;
  version;

  constructor({ db: dbNameOrGetter, stores, version, ...options }) {
    super(options);
    this.dbNameOrGetter = dbNameOrGetter;
    this.stores = stores;
    this.version = version;
  }

  performCreateEndpoint(options) {
    const resolveDb = () => openIndexedDB({ dbNameOrGetter: this.dbNameOrGetter, stores: this.stores, version: this.version });
    return new IndexedDbEndpoint({ resolveDb, ...options });
  }
}
