<template>
  <canvas
    :id="canvasId"
    ref="canvas"
    @click="handleClick"
  />
</template>

<script>
import { injectLogger } from "@/lib/logger";
import { camelCase } from "@/lib/strings";

export default {
  computed: {
    canvasId() {
      return camelCase("canvas", this.field);
    },
  },
  data() {
    return {
      isRendering: false,
    };
  },
  methods: {
    computeBorders(element) {
      const styles = window.getComputedStyle(element);

      const calc = dimension =>
        parseInt(styles[`border-${dimension}-width`].replace("px", ""));
      const left = calc("left");
      const top = calc("top");
      const right = calc("right");
      const bottom = calc("bottom");

      return { bottom, left, right, top };
    },
    computeLogicalPagePosition(screenX, screenY) {
      const canvas = this.$refs.canvas;
      const canvasRect = canvas.getBoundingClientRect();
      const canvasBorders = this.computeBorders(canvas);

      const canvasOffsetX = canvasRect.left + canvasBorders.left;
      const canvasOffsetY = canvasRect.top + canvasBorders.top;
      const relativePageX = screenX - canvasOffsetX;
      const relativePageY = screenY - canvasOffsetY;
      const proportionalX = Math.round(relativePageX / this.scale);
      const proportionalY = Math.round(relativePageY / this.scale);

      return {
        x: proportionalX,
        y: proportionalY,
      };
    },
    computeLogicalPageSize() {
      const canvas = this.$refs.canvas;
      const canvasRect = canvas.getBoundingClientRect();
      const canvasBorders = this.computeBorders(canvas);

      const relativeWidth
        = canvasRect.width - canvasBorders.left - canvasBorders.right;
      const relativeHeight
        = canvasRect.height - canvasBorders.top - canvasBorders.bottom;
      const proportionalWidth = Math.round(relativeWidth / this.scale);
      const proportionalHeight = Math.round(relativeHeight / this.scale);

      return {
        height: proportionalHeight,
        width: proportionalWidth,
      };
    },
    handleClick(event) {
      const { x, y } = this.computeLogicalPagePosition(
        event.clientX,
        event.clientY,
      );
      const { height, width } = this.computeLogicalPageSize();

      const isOutOfPage = x < 0 || y < 0 || x > width || y > height;
      if (isOutOfPage) return;

      this.$emit("pageClick", { height, width, x, y });
    },
    async renderPage() {
      const logger = injectLogger();
      if (!this.pageNumber) return logger.log("Sem número de página para renderizar pdf");
      if (!this.pdfDocument) return logger.log("Sem documento convertido para renderizar pdf");
      if (this.isRendering) return logger.log("Ainda renderizando página");

      this.isRendering = true;
      this.$emit("renderStarted");

      await this.updatePdf();
      this.updateOverlays();

      this.$emit("renderFinished");
      this.isRendering = false;
    },
    updateOverlays() {
      if (!Array.isArray(this.overlays)) return;

      const pageOverlays = this.overlays.filter(
        o => o.page === this.pageNumber,
      );
      const ctx = this.$refs.canvas.getContext("2d");
      pageOverlays.forEach(({ image, x, y }) => {
        const scale = dimension => dimension * this.scale;
        const renderX = scale(x);
        const renderY = scale(y);
        const renderWidth = scale(image.width);
        const renderHeight = scale(image.height);

        ctx.drawImage(image, renderX, renderY, renderWidth, renderHeight);
      });
    },
    async updatePdf() {
      const page = await this.pdfDocument.getPage(this.pageNumber);
      /*
        em escala igual a 1 (o que seria o zoom 100%) o componente de pdf utilizado pelo web (mozilla
        pdf.js) renderiza os documentos com dimensões proporcionalmente inferiores ao adobe reader
        na mesma configuração de zoom. ao explorar diferentes ferramentas online de leitura de pdf
        foi observado que a representação em pixels do zoom 100% varia entre as ferramentas. sendo
        assim, optou-se por manter a proporcionalidade adotada nativamente pelo mozilla pdf.js.
      */
      const viewport = page.getViewport({ scale: this.scale });

      const canvas = this.$refs.canvas;
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const ctx = canvas.getContext("2d");

      const renderTask = page.render({
        canvasContext: ctx,
        viewport,
      });

      await renderTask.promise;
    },
  },
  name: "PdfDocumentPageRender",
  props: {
    field: {
      default: "page",
      type: String,
    },
    overlays: {
      default: null,
      type: Array,
    },
    pageNumber: {
      default: null,
      type: Number,
    },
    pdfDocument: {
      default: null,
      type: Object,
    },
    scale: {
      default: 1,
      type: Number,
    },
  },
  watch: {
    /*
      até o momento, o resenho completo foi a solução adotada para apagar os overlays
      que foram desenhados na renderização anterior mas estão descartados nesse novo
      array de overlays.
    */
    overlays() {
      this.renderPage("overlays");
    },
    pageNumber() {
      this.renderPage("pageNumber");
    },
    scale() {
      this.renderPage("scale");
    },
  },
};
</script>
