<script setup>
import { createDefaultManager } from "@/data";
import { ButtonsSaveBack, ColBase, ControlCheckbox, DialogForm, MODES, RowForm, isEditMode } from "@/lib";
import { AccountDialog } from "@/views/widgets/account-dialog";
import { ref } from "vue";

defineProps({
  codigoApi: {
    required: true,
    type: Function,
  },
  contas: {
    default: null,
    type: Array,
  },
  defaultGestor: {
    default: createDefaultManager,
    type: Object,
  },
  externalErrors: {
    default: null,
    type: [Object, Error],
  },
  mode: {
    default: MODES.VIEW,
    type: String,
  },
});

const accountDialog = ref(null);
</script>
<template>
  <DialogForm
    :default-payload="defaultGestor"
    :external-errors="externalErrors"
    :external-errors-field-map="{ codigo: 'codigoGestor' }"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload, patch }">
      <AccountDialog
        ref="accountDialog"
        :value="payload"
        :codigo-api="codigoApi"
        :contas="contas"
        :mode="mode"
        field="gestor"
        @input="patch"
      />
      <RowForm v-if="isEditMode(mode)">
        <ColBase>
          <ControlCheckbox
            v-model="payload.ativo"
            field="ativo"
            placeholder="Ativar Gestor"
            switch
          />
        </ColBase>
      </RowForm>
    </template>
    <template #actions>
      <slot
        name="actions"
        :clear-account-data="() => accountDialog.reset()"
      />
      <ButtonsSaveBack @back="$emit('back')" />
    </template>
  </DialogForm>
</template>
