<template>
  <BFormCheckbox
    v-if="!hide"
    v-model="checked"
    :disabled="disabled"
    :switch="$attrs.switch"
  >
    {{ placeholder }}
  </BFormCheckbox>
</template>

<script>
import { BFormCheckbox } from "@/lib/components/bv";

export default {
  components: { BFormCheckbox },
  data() {
    return {
      checked: this.value,
    };
  },
  name: "InputCheckbox",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: true,
      type: String,
    },
    value: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    checked(newChecked) {
      this.$emit("input", newChecked);
    },
    value(newValue) {
      this.checked = newValue;
    },
  },
};
</script>
