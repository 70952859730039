import { clone, isArrayFull, isEqual } from "@/lib/aggregates";

export function setItem({ context, payload: item }) {
  if (isEqual(context.items.value[item.id], item)) return;
  context.items.value[item.id] = item;
}

export function setBatch({ context, payload: manifests }) {
  if (!isArrayFull(manifests)) return;

  // a atualização em batch copiando o estado anterior e substituindo após processar todo o manifesto busca evitar um excesso de atualizações em tela competindo por recursos enquanto cada substituição ou exclusão for feita em sequencia sobre o state. nesse formato a ideia é que o calculo de reatividade será feito uma única vez
  const newItems = clone(context.items.value);

  manifests.forEach((manifest) => {
    const { operation, payload: item } = manifest;
    if (operation === "del") delete newItems[item.id];
    if (operation === "set") newItems[item.id] = item;
  });
  context.items.value = newItems;
}
