export function idle() {
  return new Promise((resolve) => {
    // a checagem é necessária dado que o safari não suporta a web api de idle https://caniuse.com/requestidlecallback
    if (typeof window.requestIdleCallback === "function") {
      requestIdleCallback(resolve);
    }
    else {
      setTimeout(resolve, 100);
    }
  });
}
