import { Plugin } from "../plugin";
import { filterItems } from "./filter";
import { removeItem } from "./remove";
import { setBatch, setItem } from "./set";

export class ItemsPlugin extends Plugin {
  constructor({ actions = {}, state = {} } = {}) {
    super({
      actions: {
        // os métodos de seleção de dados em actions como filter não estão em state porque podem ser usados de forma independente numa mesma tela. neste caso, quem consome o filtro se quer criar um computed baseado no filtro caso precise.
        filterItems,
        removeItem,
        setBatch,
        setItem,
        ...actions,
      },
      state: {
        items: {},
        list: ({ items }) => Object.values(items.value),
        total: ({ list }) => list.value.length,
        ...state,
      },
    });
  }
}
