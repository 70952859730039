var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BTable",
        _vm._g(
          _vm._b(
            {
              staticClass: "list-base-table",
              attrs: {
                id: _vm.camelCase("list", _vm.field),
                items: _vm.items,
                fields: _vm.styledFields,
                "sort-by": _vm.initialSort.field,
                selectable: !!_vm.select,
                "select-mode": _vm.select,
                busy: _vm.isLoading,
                "primary-key": _vm.primaryKey,
                "per-page": _vm.itemsPerPage,
                "current-page": _vm.currentPage,
                "sort-desc": _vm.initialSort.direction === "desc",
                "sort-icon-left": "",
                striped: "",
                hover: "",
                stacked: "md",
                responsive: "",
                "show-empty": "",
                "empty-text": "Nenhum item encontrado",
                "empty-filtered-text": "Nenhum item encontrado na pesquisa",
                "tbody-tr-class": { "has-default-action": !!_vm.defaultAction },
              },
              on: { "row-clicked": _vm.handleClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-busy",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-4" },
                          [
                            _c("BSpinner", { staticClass: "align-middle" }),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Carregando...")]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm._l(_vm.$scopedSlots, function (_, slot) {
                    return {
                      key: slot,
                      fn: function (scope) {
                        return [_vm._t(slot, null, null, scope)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            "BTable",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _vm.items.length > 0
        ? _c("div", { staticClass: "pagination-controls" }, [
            _c("p", { staticClass: "total-items" }, [
              _vm._v(
                "\n      Total de itens: " + _vm._s(_vm.items.length) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "items-per-page-selector" }, [
              _c("label", { attrs: { for: "itemsPerPage" } }, [
                _vm._v("Itens por página:"),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.itemsPerPage,
                      expression: "itemsPerPage",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: { id: "itemsPerPage" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.itemsPerPage = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 10 } }, [
                    _vm._v("\n          10\n        "),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 15 } }, [
                    _vm._v("\n          15\n        "),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 20 } }, [
                    _vm._v("\n          20\n        "),
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: 30 } }, [
                    _vm._v("\n          30\n        "),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.items.length > _vm.itemsPerPage
        ? _c("BPagination", {
            attrs: {
              id: _vm.camelCase("pagination", _vm.field),
              "total-rows": _vm.items.length,
              "per-page": _vm.itemsPerPage,
              "aria-controls": _vm.camelCase("pagination", _vm.field),
              "first-number": "",
              "last-number": "",
              align: "center",
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }