<script setup>
import { SpinnerBase } from "@/lib/async";
import { computed } from "vue";

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  isBusy: {
    default: false,
    type: Boolean,
  },
  placeholder: {
    required: true,
    type: String,
  },
  selected: {
    required: true,
    type: Array,
  },
  state: {
    default: null,
    type: Boolean,
  },
});

const text = computed(() => {
  if (props.selected.length === 0) {
    return props.placeholder;
  }

  if (props.selected.length === 1) {
    return props.selected[0].text;
  }

  return `${props.selected.length} selecionados`;
});
</script>
<template>
  <!-- o uso de button é necessário para se beneficiar da popover api que exige que invokers sejam buttons, isso também habilita uso do teclado para abrir e fechar listagem  -->
  <button
    class="display-value"
    type="button"
    :class="{ disabled, valid: state === true, invalid: state === false }"
  >
    <div>
      {{ text }}
    </div>
    <SpinnerBase
      v-if="isBusy"
      size="var(--w-size-35)"
    />
  </button>
</template>
<style scoped>
.display-value {
  --state-color: var(--w-border-color-neutral);
  --state-shadow: var(--w-border-shadow-neutral);
}

.display-value.valid {
  --state-color: var(--w-border-color-success);
  --state-shadow: var(--w-border-shadow-success);
}

.display-value.invalid {
  --state-color: var(--w-border-color-danger);
  --state-shadow: var(--w-border-shadow-danger);
}

.display-value {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--input-lookup-font-size) * 2.5);
  padding-inline-start: var(--w-size-30);
  padding-inline-end: var(--w-size-40);
  padding-block: var(--w-size-20);

  justify-content: space-between;

  cursor: pointer;

  border: var(--w-border-neutral);
  background: transparent;

  text-align: left;
  color: inherit;
}

.display-value:focus {
  box-shadow: var(--state-shadow);
}

.display-value::after {
  content: "▼";
  position: absolute;
  right: var(--w-size-20);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.display-value.valid::after,
.display-value.invalid::after {
  color: var(--state-color)
}

.display-value.disabled {
  background-color: var(--w-color-neutral-20);
}
</style>
