var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c("ButtonSecondary", {
        attrs: {
          field: _vm.camelCase("interessado", "link"),
          label: "+ Vincular Interessado",
          size: "sm",
        },
        on: { click: _vm.handleAttach },
      }),
      _vm._v(" "),
      _c("FlowFormStakeholdersTabManagerAttachModal", {
        ref: "modal",
        attrs: { "codigo-api": _vm.codigoApi, contas: _vm.contas },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }