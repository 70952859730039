<script setup>
import { debounce } from "@/lib/async";
import { ButtonTertiary } from "@/lib/components/buttons";
import { kebabCase } from "@/lib/strings";
import { computed } from "vue";

const props = defineProps({
  add: {
    default: false,
    type: Boolean,
  },
  id: {
    required: true,
    type: String,
  },
  value: {
    default: null,
    type: String,
  },
});
const emit = defineEmits(["input", "added"]);

const debouncedInput = debounce(
  (value) => {
    emit("input", value);
  },
  200,
);

const model = computed({
  get() {
    return props.value;
  },
  set(value) {
    debouncedInput(value);
  },
});
</script>
<template>
  <div class="input-filter">
    <input
      :id="kebabCase(id, 'input')"
      v-model="model"
      type="text"
      class="input-filter__input"
    >
    <ButtonTertiary
      v-if="add"
      :id="kebabCase(id, 'add')"
      label="Novo"
      squared
      size="sm"
      @click="emit('added', model)"
    />
  </div>
</template>
<style scoped>
.input-filter {
  padding-inline: var(--w-size-30);
  display: flex;
  gap: var(--w-size-10);
}

.input-filter__input {
  width: 100%;
  padding-inline: var(--w-size-20);
  padding-block: var(--w-size-10);

  border: var(--w-border-neutral);
  outline: none;
  color: var(--w-plain-text-color);

  &:focus {
    box-shadow: var(--w-border-shadow-neutral);
  }
}
</style>
