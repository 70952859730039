var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "notifications-toast" }, [
    _c(
      "div",
      { staticClass: "notifications-toast__icon-column" },
      [_c(_setup.AppIcon, { attrs: { name: _setup.icon, "font-scale": "3" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "notifications-toast__message-column" }, [
      _c("p", [_vm._v(_vm._s(_vm.notification.message))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notifications-toast__button-column" },
      [_c(_setup.ButtonClose, { on: { click: _setup.handleClick } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }