import { AppError } from "@/lib/error";
import { DEFAULT_SEVERITY, SYSLOG_SEVERITIES, isMoreImportant } from "@/lib/severities";
import { currentTimestampWithTimezone } from "@/lib/time";

import { LOG_ERRORS } from "./errors";

export async function applyEffects({ effects, message, meta, severity }) {
  const timestamp = currentTimestampWithTimezone();

  for (const effect of effects) {
    if (isMoreImportant(effect.severity, severity)) continue;

    try {
      await effect.trigger({ message, meta, severity, timestamp });
    }
    catch {
      // todos os erros durante a execução de um efeito são ignorados. se um efeito falhar, ele não deve impedir que outros efeitos sejam executados ou produzir reações colaterais indesejadas.
    }
  }
}

export function assertEffects(effects = []) {
  if (!Array.isArray(effects))
    AppError.throw({ message: LOG_ERRORS.EFFECTS_NOT_ARRAY, severity: SYSLOG_SEVERITIES.ERROR });

  return effects.map((effect) => {
    if (typeof effect !== "function" && typeof effect.trigger !== "function")
      AppError.throw({ message: LOG_ERRORS.EFFECT_NOT_FUNCTION, severity: SYSLOG_SEVERITIES.ERROR });

    return {
      severity: effect.severity ?? DEFAULT_SEVERITY,
      trigger: effect.trigger ?? effect,
    };
  });
}
