var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-filter" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _setup.model,
            expression: "model",
          },
        ],
        staticClass: "input-filter__input",
        attrs: { id: _setup.kebabCase(_vm.id, "input"), type: "text" },
        domProps: { value: _setup.model },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _setup.model = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.add
        ? _c(_setup.ButtonTertiary, {
            attrs: {
              id: _setup.kebabCase(_vm.id, "add"),
              label: "Novo",
              squared: "",
              size: "sm",
            },
            on: {
              click: function ($event) {
                return _setup.emit("added", _setup.model)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }