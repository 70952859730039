var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.DashboardPage, {
    attrs: {
      crumbs: _vm.crumbs,
      "side-links": _setup.sidebarLinks,
      "header-text": _setup.headerTextNormalized,
      "no-card": _vm.noCard,
      busy: _vm.busy,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "navStart",
          fn: function () {
            return [_c(_setup.LogoApp, { attrs: { responsive: "" } })]
          },
          proxy: true,
        },
        {
          key: "navEnd",
          fn: function () {
            return [_c(_setup.UserMenu)]
          },
          proxy: true,
        },
        {
          key: "content",
          fn: function (slotProps) {
            return [_vm._t("default", null, null, slotProps)]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }