import { Endpoint } from "../base";
import { asArray } from "./as-array";
import { METHODS } from "./methods";

export class IndexedDbEndpoint extends Endpoint {
  method;
  resolveDb;
  storeName;

  constructor({ method, resolveDb, store: storeName, ...options }) {
    super(options);
    this.method = method;
    this.resolveDb = resolveDb;
    this.storeName = storeName;

    if (!Object.values(METHODS).includes(method))
      throw new Error(`method ${method} not supported`);
  }

  async performDispatch(payload) {
    const db = await this.resolveDb();
    const transaction = db.transaction(this.storeName, "readwrite");
    const store = transaction.objectStore(this.storeName);

    switch (this.method) {
      case METHODS.READ: {
        return new Promise((resolve, reject) => {
          const key = typeof payload === "object" ? payload.id : payload;
          const req = key ? store.get(key) : store.getAll();
          req.onsuccess = () => resolve(req.result);
          req.onerror = () => reject(req.error);
        });
      }

      case METHODS.REPLACE: {
        return new Promise((resolve, reject) => {
          const clearReq = store.clear();
          clearReq.onerror = () => reject(clearReq.error);

          clearReq.onsuccess = () => {
            const items = asArray(payload);
            items.forEach(item => store.put(item));

            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
          };
        });
      }

      case METHODS.UPSERT: {
        const items = asArray(payload);
        items.forEach(item => store.put(item));
        return new Promise((resolve, reject) => {
          transaction.oncomplete = () => resolve();
          transaction.onerror = () => reject(transaction.error);
        });
      }
    }
  }
}
