import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

export function provide({ instance, key }) {
  if (!key) {
    AppError.throw({
      message: "A key must be provided",
      severity: SYSLOG_SEVERITIES.CRITICAL,
    });
  }

  if (globalThis[key]) {
    AppError.throw({
      message: `The key "${key}" is already in use`,
      severity: SYSLOG_SEVERITIES.CRITICAL,
    });
  }

  globalThis[key] = instance;
}

export function inject(key) {
  if (!key) {
    AppError.throw({
      message: "A key must be provided",
      severity: SYSLOG_SEVERITIES.CRITICAL,
    });
  }

  const instance = globalThis[key];

  if (!instance) {
    AppError.throw({
      message: `The instance for key "${key}" was not provided`,
      severity: SYSLOG_SEVERITIES.CRITICAL,
    });
  }
  return instance;
}
