import { isArray } from "./is-array";
import { isArrayFull } from "./is-array-full";
import { isObjectFull } from "./is-object-full";

export function asIndex(source, { field = "id" } = {}) {
  if (isObjectFull(source) && !isArray(source)) return source;
  if (!isArrayFull(source)) return {};

  const result = {};

  // o for é o mecanismo mais rápido para iterar sobre grandes arrays.
  for (let i = 0; i < source.length; i++) {
    const item = source[i];
    const index = item[field];
    result[index] = item;
  }

  return result;
}
