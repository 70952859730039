import { createApi } from "@/lib";

import { decodeDocument } from "./code";

export function createDocumentsEndpoints(backendApi) {
  const downloadApi = createApi({ type: "http" });
  const documentsUrl = "/v1/documentos";

  return {
    documentDelete: backendApi.createEndpoint({
      method: "delete",
      url: id => `${documentsUrl}/${id}`,
    }),

    documentDownload: downloadApi.createEndpoint({
      method: "get",
      responseType: "blob",
      url: url => url, // a url de download é dinâmicamente gerada pelo backend e possui tempo de expiração. ela é passada pelo backend como retorno de chamadas como criação de fluxo ou anexação de documento. aqui colocamos como função para permitir que quem utilize o endpoint possa passar a url completa no momento da requisição.
    }),

    documentUpload: backendApi.createEndpoint({
      contentType: "multipart/form-data",
      decode: decodeDocument,
      encode: ({ file, idFluxo }) => ({ doc: file, idFluxo }),
      method: "post",
      url: documentsUrl,
    }),
  };
}
