import { inject, provide } from "@/lib/use";

import { key } from "./key";

export function provideUnifiedStore(store) {
  provide({ instance: store, key });
}

export function injectUnifiedStore() {
  return inject(key);
}
