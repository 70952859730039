import { createApi } from "@/lib";

export const cacheApiSchema = {
  stores: {
    flowsArchived: "flowsArchived",
    groups: "groups",
    managers: "managers",
    requesters: "requesters",
    stakeholders: "stakeholders",
  },
  version: 4,
};

export function createCacheApi(getAccountId) {
  const { stores: storesObject, version } = cacheApiSchema;
  const stores = Object.values(storesObject);
  return createApi({ db: getAccountId, stores, type: "indexedDb", version });
}
