<template>
  <ControlInput
    :rules="composedRules"
    type="email"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { ControlInput } from "../control-input";

export default {
  components: { ControlInput },
  computed: {
    composedRules() {
      const externalRules = this.rules ? `|${this.rules}` : "";
      return `email${externalRules}`;
    },
  },
  name: "ControlEmail",
  props: {
    label: {
      default: "E-mail",
      type: String,
    },
    rules: {
      default: "",
      type: String,
    },
  },
};
</script>
