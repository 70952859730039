var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "TabAccordion",
    _vm._b(
      {
        attrs: {
          field: "interessados",
          "header-title": "Interessados",
          "header-icon-name": "people",
          "header-icon-badge": _vm.howManyInteressados,
        },
      },
      "TabAccordion",
      _vm.$attrs,
      false
    ),
    [
      _c("FlowFormStakeholdersTabManager", {
        attrs: {
          interessados: _vm.fluxo.interessados,
          "id-fluxo": _vm.fluxo.id,
          "is-fluxo-iniciado": _vm.isFluxoIniciado,
          "is-sequencial": _vm.fluxo.dadosBasicos.isSequencial,
          disabled: _vm.disabled,
          "codigo-api": _vm.codigoApi,
          contas: _vm.contas,
        },
        on: {
          "update:interessados": function ($event) {
            return _vm.$set(_vm.fluxo, "interessados", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }