<script setup>
import { DashboardPage, LogoApp, injectLegacyStore } from "@/lib";
import { computed } from "vue";

import { sidebarLinksFull } from "../sidebar-links";
import { UserMenu } from "../user-menu";

const props = defineProps({
  busy: {
    default: false,
    type: [Boolean, String],
    validator(value) {
      if (value === null || value === undefined) return true;
      if (typeof value === "boolean") return true;
      return ["idle", "progress", "skeleton", "spinner"].includes(value);
    },
  },
  crumbs: {
    required: true,
    type: Array,
  },
  headerText: {
    default: null,
    type: String,
  },
  noCard: {
    default: false,
    type: Boolean,
  },
});

const store = injectLegacyStore();

const sidebarLinks = computed(() => {
  return sidebarLinksFull.filter(link => store.getters["auth/perfilIsAnyOf"](link.perfis));
});

const headerTextNormalized = computed(() => {
  if (props.headerText) return props.headerText;
  return props.crumbs.at(-1).text;
});
</script>
<template>
  <DashboardPage
    :crumbs="crumbs"
    :side-links="sidebarLinks"
    :header-text="headerTextNormalized"
    :no-card="noCard"
    :busy="busy"
  >
    <template #navStart>
      <LogoApp responsive />
    </template>
    <template #navEnd>
      <UserMenu />
    </template>

    <template #content="slotProps">
      <slot v-bind="slotProps" />
    </template>
  </DashboardPage>
</template>
