import "core-js/stable"; // core e regenerator possibilitam o uso das notações async e await em funções assíncronas em conjunto com babel
import { SYSLOG_SEVERITIES, installComponentsGlobalDependencies, installValidationSuite, provideLegacyStore, provideLogger, provideUnifiedStore } from "@/lib";
import "regenerator-runtime/runtime";
import Vue from "vue";

import manifest from "../package.json";
import { initApisEndpoints } from "./app/apis";
import "./views/design"; // carrega os estilos personalizados e também das bibliotecas bootstrap e bootstrap-vue
import { initEnvironment } from "./app/environment";
import { initApp } from "./app/init-app";
import { initLogger } from "./app/logger";
import { initRouter } from "./app/router";
import { initLegacyStore, initUnifiedStore } from "./app/stores";

async function main() {
  Vue.config.productionTip = false;

  // injetando propriedades e funções comuns em todos os componentes
  installComponentsGlobalDependencies(Vue);

  const environment = initEnvironment(manifest);
  const endpoints = initApisEndpoints({ environment, getAccountId, getToken });

  const store = initLegacyStore({ endpoints, environment });
  provideLegacyStore(store);

  const unifiedStore = initUnifiedStore({ endpoints, onAuthChange });
  provideUnifiedStore(unifiedStore);

  const logger = initLogger({ environment, globals: { Vue, window }, store, webLoggerEndpoint: endpoints.logger });
  provideLogger(logger);

  const router = initRouter({ environment, store });
  const app = initApp({ endpoints, environment, router, store });

  // instala componentes, regras e diretivas ligadas à validação de entradas do usuário
  installValidationSuite();

  // expondo globals em window para uso pela suite de testes e funções setup que não conseguem acessar a instância de vue. poderá ser substituído por provide/inject após migração para vue 3
  window.$app = app;
  window.$environment = environment;
  window.$endpoints = endpoints;

  app.$mount("#app");

  // contato para ver se o servidor está acessível
  await endpoints.hello.dispatch();

  logger.log({ message: `${manifest.name} v${manifest.version} inicializado`, severity: SYSLOG_SEVERITIES.INFO });

  function getToken() {
    return store.getters["auth/token"];
  }

  function getAccountId() {
    return store.getters["auth/idConta"];
  }

  function onAuthChange(observer) {
    const getUserPerfil = (state, getters) => getters["auth/perfilIfSignedIn"];
    store.watch(getUserPerfil, observer);
  }
}

main();
