<template>
  <BFormSelect
    v-if="!hide"
    :disabled="disabled"
    :options="options"
    :text-field="textField"
    :value-field="valueField"
    :class="{ 'read-only': disabled }"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { BFormSelect } from "@/lib/components/bv";

export default {
  components: { BFormSelect },
  name: "SelectSingleBase",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    options: {
      required: true,
      type: Array,
    },
    textField: {
      default: "text",
      type: String,
    },
    valueField: {
      default: "value",
      type: String,
    },
  },
};
</script>

<style scoped>
/*
    as tags !important estão sendo usadas para conseguir sobrescrever os estilos
    do framework bootstrap-vue.
  */
.read-only {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  color: var(--w-color-neutral-50) !important;
  background: none !important;
  opacity: 1;
}
</style>
