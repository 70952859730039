var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ControlCheckbox, {
    attrs: {
      value: _vm.value,
      field: "approval-icp",
      disabled: _vm.disabled,
      placeholder: "Requer uso de certificado ICP-Brasil.",
      "helper-text":
        "O assinante deve possuir um certificado emitido por uma autoridade ICP-Brasil.",
      switch: "",
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }