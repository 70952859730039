import { asArray, isArrayFull } from "@/lib/aggregates";
import { idle } from "@/lib/async";

export function createQueryFeatures(config) {
  return {
    actions: {
      onTurnOff: stopItemsRefreshment,
      onTurnOn: createLoadItemsFromCacheOrRemote(config),
      startRefreshingItemsFromRemote: createStartRefreshingItemsFromRemote(config),
      stopItemsRefreshment,
    },
    getters: {
      getFrequency: () => config.pollingSeconds * 1000,
      getTimeoutId: state => state.timeoutId,
      hasQueriedOnce: state => state.hasQueriedOnce,
      howManyQuerying(state, getters, rootState) {
        const modules = Object.values(rootState);
        const pendingQueries = modules.reduce((acc, module) => {
          if (!module.isQuerying) return acc;
          return acc + 1;
        }, 0);
        return pendingQueries;
      },
      isQuerying: state => state.isQuerying,
    },
    mutations: {
      setTimeoutId: (state, id) => (state.timeoutId = id),
      startQuerying: (state) => {
        state.isQuerying = true;
      },
      stopQuerying: (state) => {
        state.isQuerying = false;
        if (!state.hasQueriedOnce) {
          state.hasQueriedOnce = true;
        }
      },
    },
    state: {
      // é usada para controlar efeitos colaterais a serem aplicados após que a primeira query completa foi concluída como por exemplo a carga de dados numa tela de cadastro.
      hasQueriedOnce: false,
      isQuerying: false,
      timeoutId: null,

    },
  };
}

function createLoadItemsFromCacheOrRemote(config) {
  const { cacheEndpoints, queryEndpoint } = config;

  return async ({ commit, dispatch }) => {
    commit("startQuerying");
    try {
      const { items: cachedItems, success } = await downloadCachedItems(cacheEndpoints);

      if (success) {
        await dispatch("resetItemsFrom", { items: cachedItems, origin: "cache" });
      }
      else {
        const { items: remoteItems } = await downloadRemoteItems(queryEndpoint);
        await dispatch("resetItemsFrom", { items: remoteItems, origin: "remote" });
      }

      dispatch("startRefreshingItemsFromRemote");
    }
    finally {
      commit("stopQuerying");
    }
  };
}

function createStartRefreshingItemsFromRemote(config) {
  const { queryEndpoint } = config;

  return ({ commit, dispatch, getters }, { now = false } = {}) => {
    if (now) {
      refresh();
    }
    else {
      schedule();
    }

    function schedule() {
      // evita que o polling seja agendado mais de uma vez dado que a atualização pode ser antecipada manualmente na ui
      dispatch("stopItemsRefreshment");

      // artificialAsync introduz um distanciamento aleatório de até 60 segundos no próximo polling para evitar que as várias lojas de dados iniciem seus requests simultaneamente
      const artificialAsyncBase = 60;
      const artificialAsync = Math.round(Math.random() * artificialAsyncBase * 1000);
      const nextCall = getters.getFrequency + artificialAsync;
      const id = setTimeout(refresh, nextCall);
      commit("setTimeoutId", id);
    }

    async function refresh() {
      commit("startQuerying");

      try {
        // evita que o polling seja agendado mais de uma vez dado que a atualização pode ser antecipada manualmente na ui
        dispatch("stopItemsRefreshment");

        // idle é usado para evitar bloquear a ui. especialmente em casos de pollings simultâneos de coleções diferentes
        await idle();
        const { items: remoteItems } = await downloadRemoteItems(queryEndpoint);

        await idle();
        await dispatch("reconciliateItemsFromRemote", remoteItems);

        schedule();
      }
      finally {
        commit("stopQuerying");
      }
    }
  };
}

function stopItemsRefreshment({ getters }) {
  clearTimeout(getters.getTimeoutId);
}

async function downloadCachedItems(cacheEndpoints) {
  if (!cacheEndpoints) return { success: false };

  const cachedItems = await cacheEndpoints.read.dispatch();
  if (!isArrayFull(cachedItems)) return { success: false };

  return { items: cachedItems, success: true };
}

async function downloadRemoteItems(queryEndpoint) {
  const allItems = [];

  let pageNumber = 0;
  let hasItems;
  do {
    const result = await queryEndpoint.dispatch(pageNumber);
    const pageItems = asArray(result?.data);

    hasItems = isArrayFull(pageItems);
    if (hasItems) {
      allItems.push(...pageItems);
      pageNumber++;
    }
  } while (hasItems);

  return { items: allItems, success: true };
}
