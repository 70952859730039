import { CollectionPlugin } from "@/lib/stores";

import { fromEndpointToUnifiedPageDispatch } from "../helpers";

export function createClients(endpoints) {
  const pageDispatch = fromEndpointToUnifiedPageDispatch(endpoints.clientsList);
  return {
    actions: {
      async add({ context, payload: userClient }) {
        const responseClient = await endpoints.clientAdd.dispatch(userClient);
        context.setItem(responseClient);
      },
      async edit({ context, payload: userClient }) {
        const responseClient = await endpoints.clientEdit.dispatch(userClient);
        context.setItem(responseClient);
      },
    },
    plugins: [new CollectionPlugin({ pageDispatch })],
  };
}
