var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "body-actions-body" },
        [_c(_setup.GapperBase, [_vm._t("body")], 2)],
        1
      ),
      _vm._v(" "),
      _c(_setup.PanelButtons, {
        attrs: { "show-divider": _vm.showDivider },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [_vm._t("actions")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }