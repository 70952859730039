import { HttpApi } from "../http";
import { IndexedDbApi } from "../indexed-db";
import { LocalStorageApi } from "../local-storage";
import { ResolverApi } from "../resolver";

export function createApi({ type, ...apiOptions } = {}) {
  switch (type) {
    case "http":
      return new HttpApi(apiOptions);
    case "indexedDb":
      return new IndexedDbApi(apiOptions);
    case "localStorage":
      return new LocalStorageApi(apiOptions);
    case "resolver":
      return new ResolverApi(apiOptions);
    default:
      throw new Error("api type invalid");
  }
}
