import { decodeRequester, encodeRequester } from "./code-requester";

export function createRequestersEndpoints({ backendApi, cacheApi, cacheApiSchema, pageLimit }) {
  const requestersUrl = "/v1/contas/perfil/REQUISITANTE";

  return {
    requesterAdd: backendApi.createEndpoint({
      decode: decodeRequester,
      encode: encodeRequester,
      method: "post",
      url: requestersUrl,
    }),

    requesterEdit: backendApi.createEndpoint({
      decode: decodeRequester,
      encode: encodeRequester,
      method: "put",
      url: ({ id }) => `${requestersUrl}/${id}`,
    }),

    requesterWebhookTest: backendApi.createEndpoint({
      method: "get",
      url: requesterId => `/v1/webhook/validar/${requesterId}`,
    }),

    requestersCacheRead: cacheApi.createEndpoint({
      method: "read",
      store: cacheApiSchema.stores.requesters,
    }),

    requestersCacheReplace: cacheApi.createEndpoint({
      method: "replace",
      store: cacheApiSchema.stores.requesters,
    }),

    requestersCacheUpsert: cacheApi.createEndpoint({
      method: "upsert",
      store: cacheApiSchema.stores.requesters,
    }),

    requestersList: backendApi.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeRequester), pagination }),
      method: "get",
      url: page => `${requestersUrl}?limit=${pageLimit}&offset=${page * pageLimit}`,
    }),
  };
}
