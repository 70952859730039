<script setup>
import NotificationsToast from "./notifications-toast.vue";
import { installNotifications } from "./use";

const notificationsService = installNotifications();
const notificationsList = notificationsService.list();
</script>
<template>
  <div class="notifications-global">
    <div class="notifications-global__toasts">
      <div
        v-for="notification in notificationsList"
        :key="notification.id"
      >
        <NotificationsToast :notification="notification" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.notifications-global__toasts {
  position: fixed;
  top: var(--w-size-30);
  right: var(--w-size-30);
  width: var(--w-size-70);

  display: flex;
  flex-direction: column;
  gap: var(--w-size-20);
  z-index: 9999;
}
</style>
