<script setup>
import { createDefaultConta } from "@/data";
import { AlertText, ColBase, ControlCelular, ControlEmail, ControlInput, ControlSelectSingle, GapperBase, MODES, OverlayBase, RowForm, camelCase, isAddMode, isArrayFull, isEditMode, isViewMode, useAsyncSearch } from "@/lib";
import { computed, ref } from "vue";

const props = defineProps({
  celularLabel: {
    default: "Celular",
    type: String,
  },
  codigoApi: {
    default: null,
    type: Function,
  },
  contas: {
    default: null,
    type: Array,
  },
  emailLabel: {
    default: "E-mail",
    type: String,
  },
  field: {
    required: true,
    type: String,
  },
  mode: {
    default: MODES.VIEW,
    type: String,
  },
  value: {
    default: createDefaultConta,
    type: Object,
  },
});
const emit = defineEmits(["input"]);
defineExpose({ reset });

const { isSearching: isSearchingCodigo, resetSearchState: resetCodigoSearch, search: searchCodigo, searchResult: searchCodigoResult, searchSucceeded: searchCodigoSucceeded } = useAsyncSearch(props.codigoApi);

const contaLookupSucceeded = ref(false);

const areContactFieldsEnabled = computed(() => (isAddMode(props.mode) || isEditMode(props.mode)) && !contaLookupSucceeded.value);
const areIdentityFieldsEnabled = computed(() => isAddMode(props.mode) && !contaLookupSucceeded.value && !searchCodigoSucceeded.value);

const isCodigoSearchEnabled = computed(() => isAddMode(props.mode) && props.value.tipoCodigo === "CPF" && !contaLookupSucceeded.value);
const isLookupEnabled = computed(() => isAddMode(props.mode) && isArrayFull(props.contas) && !searchCodigoSucceeded.value && !contaLookupSucceeded.value);

const lookupOptions = computed(() => isLookupEnabled.value ? props.contas : null);

async function handleCodigoSearch() {
  if (!isCodigoSearchEnabled.value) return;
  await searchCodigo(props.value.codigo);
  if (searchCodigoSucceeded.value) {
    handleEmitUpdate({ nome: searchCodigoResult.value.data.nome });
  }
}

function handleLookup(conta) {
  if (!conta) return;
  contaLookupSucceeded.value = true;
  handleEmitUpdate(conta);
}

function reset() {
  if (isEditMode(props.mode) || isViewMode(props.mode)) return;
  handleEmitUpdate(createDefaultConta());
  contaLookupSucceeded.value = false;
  resetCodigoSearch();
}

function handleEmitUpdate(partial) {
  const newValue = { ...props.value, ...partial };
  emit("input", newValue);
}
</script>
<template>
  <OverlayBase :is-busy="isSearchingCodigo">
    <GapperBase>
      <AlertText
        v-if="isAddMode(props.mode)"
        text="O Tipo e o Código do usuário não poderão ser alterados no futuro"
      />
      <RowForm>
        <ColBase sm="4">
          <ControlSelectSingle
            :field="camelCase('tipoCodigo', field)"
            :value="value.tipoCodigo"
            :options="['CPF', 'GENERICO']"
            :disabled="!areIdentityFieldsEnabled"
            label="tipo"
            @input="handleEmitUpdate({ 'tipoCodigo': $event })"
          />
        </ColBase>
        <ColBase sm="8">
          <ControlInput
            :field="camelCase('codigo', field)"
            :value="value.codigo"
            :rules="`required${value.tipoCodigo === 'CPF' ? '|cpf' : ''}`"
            :mask="value.tipoCodigo === 'CPF' ? '###.###.###-##' : null"
            :disabled="!areIdentityFieldsEnabled"
            label="código"
            :lookup-options="lookupOptions"
            lookup-text-field="codigo"
            lookup-value-field="id"
            @lookup="handleLookup"
            @input="handleEmitUpdate({ 'codigo': $event })"
            @blur="handleCodigoSearch"
          />
        </ColBase>
      </RowForm>
      <AlertText
        v-if="isAddMode(props.mode) && value.tipoCodigo === 'GENERICO'"
        variant="info"
        text="Usuário do tipo GENERICO não pode assinar fluxos que requeiram uso de certificado ICP-Brasil"
      />
      <RowForm>
        <ColBase sm>
          <ControlInput
            :field="camelCase(field, 'nome')"
            :value="value.nome"
            :disabled="!areIdentityFieldsEnabled"
            rules="required|min:3|max:255"
            label="Nome"
            :lookup-options="lookupOptions"
            lookup-text-field="nome"
            lookup-value-field="id"
            @lookup="handleLookup"
            @input="handleEmitUpdate({ 'nome': $event })"
          />
        </ColBase>
      </RowForm>

      <RowForm>
        <ColBase sm>
          <ControlEmail
            :value="value.email"
            :field="camelCase(field, 'email')"
            :disabled="!areContactFieldsEnabled"
            rules="required"
            :label="emailLabel"
            :lookup-options="lookupOptions"
            lookup-text-field="email"
            lookup-value-field="id"
            @lookup="handleLookup"
            @input="handleEmitUpdate({ 'email': $event })"
          />
        </ColBase>
        <ColBase sm>
          <ControlCelular
            :value="value.celular"
            :field="camelCase(field, 'celular')"
            :disabled="!areContactFieldsEnabled"
            :label="celularLabel"
            @input="handleEmitUpdate({ 'celular': $event })"
          />
        </ColBase>
      </RowForm>
    </GapperBase>
  </OverlayBase>
</template>
