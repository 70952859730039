import { Endpoint } from "./endpoint";

export class Api {
  createEndpoint(options) {
    const endpoint = this.performCreateEndpoint(options);
    this[options.name] = endpoint;
    return endpoint;
  }

  performCreateEndpoint(options) {
    // should be overrided by subclasses, endpoint class is here for testing purposes
    return new Endpoint(options);
  }
}
