import { ref, watch } from "vue";

import { useExecute } from "../execute";

// o composable é utilizado para monitorar e responder aos estados de carregamento de módulos que possuem as features de query.

export function useQueryState({ afterFirstQuery, modules, store }) {
  const getAreModulesQuerying = createAreModulesQuerying(store, modules);

  const isBusyWithQuerying = ref(getAreModulesQuerying());

  watch(
    getAreModulesQuerying,
    newValue => (isBusyWithQuerying.value = newValue),
    { immediate: true },
  );

  const getQueriedOnce = createQueriedOnce(store, modules);
  const { execute: executeAfterFirstQuery } = useExecute({ retry: true });

  // o estado de carregamento inicial é definido como true se não foi feita a primeira query ou se foi passado uma saga para ser executado após a primeira query.
  const isBusyWithFirstQuerying = ref(!getQueriedOnce() || !!afterFirstQuery);

  watch(
    getQueriedOnce,
    async (queriedOnce) => {
      if (!queriedOnce) {
        isBusyWithFirstQuerying.value = true;
        return;
      }

      if (!afterFirstQuery) {
        isBusyWithFirstQuerying.value = false;
        return;
      }

      await executeAfterFirstQuery(afterFirstQuery);
      isBusyWithFirstQuerying.value = false;
    },
    {
      immediate: true,
    },
  );

  return { isBusyWithFirstQuerying, isBusyWithQuerying };
}

function createAreModulesQuerying(store, modules) {
  return () => {
    if (!store || !modules) return false;

    const exist = m => store.hasModule(m);
    const isQuerying = m => store.getters[`${m}/isQuerying`];
    return modules.some(m => !exist(m) || isQuerying(m));
  };
}

function createQueriedOnce(store, modules) {
  return () => {
    // retorna true se não foi passado store ou modules para apoiar o uso em páginas genérica onde não foi passado store ou módulos. ao retornar true nessas situações o composable passa o retorno de que qualquer pendências de carga de dados foi resolvida e não é necessário exibir estado de carregamento.
    if (!store || !modules) return true;

    const exist = m => store.hasModule(m);
    const queriedOnce = m => store.getters[`${m}/hasQueriedOnce`];
    return modules.every(m => exist(m) && queriedOnce(m));
  };
}
