import { inject, provide } from "@/lib/use";

import { key } from "./key";

export function provideRouter(router) {
  provide({ instance: router, key });
}

export function injectRouter() {
  return inject(key);
}
